
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'

import LocationState, { ILocationState } from '../../store/modules/locations';
import { ILocation } from '../../store/interfaces/ILocation';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, IRoomListDisplay } from '../../store/interfaces/IRoom';
import ReceiverState, { IReceiverState } from '../../store/modules/receivers';
import { IReceiverDropDownList } from '../../store/interfaces/IReceiver';
import UserState, { IUserState } from '../../store/modules/users';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import { IOrganization } from '../../store/interfaces/IOrganization';
import SortService from '../../services/SortService';
import SubscriptionStatusProgress from '../../components/SubscriptionStatusProgress.vue'
import Icon from '../../components/Icon.vue'
import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
import TierState, { ITierState } from '../../store/modules/tier';
import TierLimit from '../../components/TierLimit.vue';

import { mdiDelete, mdiCog, mdiExclamationThick, mdiCheckBold, mdiHelp } from '@mdi/js';
import { IRoomModelSearch } from '../../store/interfaces/IModelSearch';
import { IOnboardStatus } from '../../store/interfaces/IOnboardingStatus';
import SignageListState, { ISignageListState } from '../../store/modules/signageList';
import { ISignageList }  from '../../store/interfaces/ISignage';

let locationState = {} as ILocationState;
let roomState = {} as IRoomState;
let receiverState = {} as IReceiverState;
let userState = {} as IUserState;
let organizationState = {} as IOrganizationState;
let onboardStatusState = {} as IOnboardStatusState;
let signageListState = {} as ISignageListState;
let tierState = {} as ITierState;

export interface IDisplayLocation extends ILocation {
    displayName:string
}

export interface IDisplayRoom extends IRoom {
    signageLists?: ISignageList[];
}

export default defineComponent({
    name: 'RoomsView',
    components: {
        SubscriptionStatusProgress,
        Icon,
        TierLimit
    },
    data() {
        return {
            domLoaded: false as boolean,
            scopedAllRooms: [] as IRoom[],
            availableReceivers: [] as IReceiverDropDownList[],
            organization: {} as IOrganization,
            locationList: [] as any[],
            locationFilter: '',
            activeFilter: '',
            statusFilter: '',
            roomSearch: '',
            viewLoading: true,
            roomBeingAddedTo: {} as IRoom,
            locationToLink: {} as ILocation,
            receiverToLink: null as any,
            locationPickerVisible: false,
            receiverPickerVisible: false,
            roomSettingsVisible: false,
            roomSettingsLoading: false,
            addReceiversApiLoading: false,
            addLocationsApiLoading: false,
            showTierDialog: false,
            roomModifiedToHighlight: {} as IRoom,
            selectedRooms: [] as IRoomListDisplay[],
            mdiCheckBold,
            mdiDelete,
            mdiCog,
            mdiExclamationThick,
            mdiHelp,
            roomSearchResults: [] as IRoomListDisplay[],
            roomCount: 10 as number,
            searchDebounce: null as any,
            paging: {
                pageSize: 10,
                pageIndex: 1,
                sortField: 'roomName',
                sortOrder: 'asc'
            } as IRoomModelSearch,
            isSignageListModalVisible: false,
            signageListModalTitle: '',
            selectedRoomLists: [] as ISignageList[],
            signageLists: [] as ISignageList[]
        }
    },
    inject: [
        'windowWidth',
        'userOnboardStatus',
        'currentStep',
        'hideOnboardingByAccess'
    ],
    updated() {
        this.$nextTick(()=> {
            this.domLoaded = true;
        });
    },
    computed: {
        canActivateReceivers(): boolean {
            return tierState.canActivateReceivers;
        },

        tierLimitReachedMessage() {
            return 'Upgrade your Ditto account to link additional rooms and receivers.'
        },

        tableRowClassName({row}: any) {
            if((row && this.roomModifiedToHighlight) && row.id == this.roomModifiedToHighlight.id) {
                return 'success-row';
            }
            return '';
        },
        
        noReceiversAvailable(): boolean {
            if(this.availableReceivers.length == 0) {return true}
            return false;
        },

        noReceiversAvailablePlaceholder(): string {
            if(this.availableReceivers.length == 0) {return 'No Receivers Available'}
            return 'Select a receiver to link';
        },

        noLocationsAvailable(): boolean {
            if(!this.locationList || this.locationList.length == 0) {return true}
            return false;
        },

        noLocationsAvailablePlaceholder(): string {
            if(!this.locationList || this.locationList.length == 0) {return 'No Locations Available'}
            return 'Select a location to link';
        },
        filterEnabled(): boolean {
            return this.searchDebounce || this.locationFilter || this.activeFilter || this.statusFilter || this.roomSearch ? true : false;
        }
    },

    methods: {
        sortTable(e: any) {
            this.paging.sortField = !e.prop ? 'roomName' : e.prop.split(',')[0];
            this.paging.sortOrder = e.order == 'descending' ? 'desc' : 'asc';

            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.doRoomSearch(false);
            this.viewLoading = true;
            }, 1000);  
        },

        signageListsForRoom(id: string): ISignageList[] {
            return this.signageLists.filter(x => {
                const roomIds = x.rooms.map(x=>x.id);
                if(roomIds.includes(id)) return true;
                return false;
            })
        },
        
        async onCreate() {
            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog) {
                await onboardStatusState.nextStep();
            }
            this.$router.push({ path: `/rooms/new` });
        },

         async createNewOnboarding() {
            let newOnboarding = {} as IOnboardStatus;
            newOnboarding.organization = userState.me && userState.me.organization ? userState.me.organization : undefined;
            newOnboarding.user = userState.me;
            newOnboarding.initiatedUser = userState.me;
            newOnboarding.isOnboarding = true;
            newOnboarding.showDialog = true;
            newOnboarding.guideStepId = 'overviewInitial';
            newOnboarding.step = 1;

            await onboardStatusState.createOnboardStatus(newOnboarding);
            await onboardStatusState.fetchOnboardStatus(true);
            if(!(this as any).hideOnboardingByAccess.value) {
                onboardStatusState.start();
            }
        },

        async loadOrganization() {
            if(userState.me && userState.me.organization) {
                await organizationState.fetchOrganization(userState.me.organization.id, false);
                this.organization = Object.assign({}, organizationState.getOrganization);
                return;
            }
            return;
        },

        roomSettingsCancel() {
            this.loadOrganization();
            this.roomSettingsVisible = false;
        },

        addReceiverTableHandler(room: IRoom) {
            if(!this.canActivateReceivers) {
                this.showTierDialog = true;
                return;
            }

            this.receiverPickerVisible = true;
            this.roomBeingAddedTo = Object.assign({}, room);
        },

        addLocationsTableHandler(room: IRoom) {
            this.locationPickerVisible = true;
            this.roomBeingAddedTo = Object.assign({}, room);
        },

        async addReceiversHandler() {
            let onboardingRoom: boolean = ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.room && (this as any).userOnboardStatus.value.room.id == this.roomBeingAddedTo.id) ?? false;

            await (this as any).$confirm(`${onboardingRoom ? 'This room was created using the Ditto setup tutorial. Editing this room before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. ' : '' }Are you sure you want to add a receiver to this room`, 'Warning', {
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

            const receiverIds = [] as string[];
            receiverIds.push(this.receiverToLink.id);
            this.addReceiversApiLoading = true;

            const payload = {
                receiverIds: receiverIds,
                roomId: this.roomBeingAddedTo.id
            }

            const storeResponse = await roomState.addReceivers(payload);

            this.addReceiversApiLoading = false;
            this.receiverPickerVisible = false; 

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Adding receiver to room failed: ${storeResponse.reason}` : 'Adding receiver to room failed');
                return;
            }

            if(onboardingRoom) {
                await onboardStatusState.end();
                await this.createNewOnboarding();
            }

            this.roomModifiedToHighlight = this.roomBeingAddedTo;
            setTimeout(() => this.roomModifiedToHighlight = {} as IRoom, 350);
            this.receiverToLink = null;
            const promises = [
                this.loadReceiverList(),
                this.doRoomSearch(false)
            ];

            await Promise.all(promises);
           
            await tierState.fetchActivateReceiversCheck(true);

            (this as any).$message.success('Receiver has been added to room.');

        },

        async addLocationHandler() {
            let onboardingRoom: boolean = ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.room && (this as any).userOnboardStatus.value.room.id == this.roomBeingAddedTo.id) ?? false;

            await (this as any).$confirm(`${onboardingRoom ? 'This room was created using the Ditto setup tutorial. Editing this room before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. ' : '' }Are you sure you want to add a location to this room`, 'Warning', {
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

            this.addLocationsApiLoading = true;

            // clean up location
            const location = {id:this.roomBeingAddedTo.location.id, type: 'Location'};
            this.roomBeingAddedTo.location = location;

            const storeResponse = await roomState.updateRoom(this.roomBeingAddedTo);
            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Adding room to location: ${storeResponse.reason}` : 'Adding room to location failed');
                return;
            }

             if(onboardingRoom) {
                await onboardStatusState.end();
                await this.createNewOnboarding();
            }

            this.addLocationsApiLoading = false;
            this.locationPickerVisible = false;

            this.roomModifiedToHighlight = this.roomBeingAddedTo;
            setTimeout(() => this.roomModifiedToHighlight = {} as IRoom, 2000);
            (this as any).$message.success('Room has been added to location.');

            this.doRoomSearch(false)

        },

        async updateOrganization() {
            this.roomSettingsLoading = true;

            const storeResponse = await organizationState.updateOrganization(this.organization);

            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Updating room settings: ${storeResponse.reason}` : 'Updating room settings failed');
                this.roomSettingsLoading = false;
                return;
            }

            this.loadOrganization();

            this.roomSettingsLoading = false;
            this.roomSettingsVisible = false;
            (this as any).$message.success('Room settings have been updated.');
        },

        handleSelectionChange(e: any) {
            this.selectedRooms = e;
        },

        handleCurrentChange(row: any, column: any) {
            if(column.property === "id") return;
            this.$router.push({ path: `/rooms/${row.id}` })
        },

        async deactivateRooms() {
            if(!this.selectedRooms || this.selectedRooms.length == 0) return;

            let selectedRoomIds = this.selectedRooms.map((x: any) => x.id);
            let onboardingRoom: boolean = ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.room && selectedRoomIds.includes((this as any).userOnboardStatus.value.room.id)) ?? false;

            await (this as any).$confirm(`${onboardingRoom ? 'A selected room was created using the Ditto setup tutorial. Editing this room before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. ' : '' }Are you sure you want to deactivate ${this.selectedRooms.length > 1 ? 'these' : 'this'} room${this.selectedRooms.length > 1 ? 's' : ''}? This will deactivate all attached receivers.`, 'Warning', {
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

            const unlinkResponse = await roomState.unlinkRooms(this.selectedRooms.map(x=>x.id));

            if(!unlinkResponse.success) {
                // throw error message...
                (this as any).$message.error(unlinkResponse.reason ? `Deactivating rooms failed: ${unlinkResponse.reason}` : 'Deactivating rooms failed');
                return;
            }

            if(onboardingRoom) {
                await onboardStatusState.end();
                await this.createNewOnboarding();
            }

            const promises = [
                this.loadReceiverList(),
                this.doRoomSearch(false)
            ];

            await Promise.all(promises);
            
            await tierState.fetchActivateReceiversCheck(true);

            (this as any).$message.success('Rooms deactivated');
        },

        async deleteRoom(id: string) {
            if(!this.roomSearchResults) return;
            const room = this.roomSearchResults.find((x) => x.id === id);
            if(!room) return;
            if(this.selectedRooms.map(x => x.id).indexOf(room.id) === -1) {
                this.selectedRooms.push(room);
            }
            await this.massDeleteRooms();
        },

        async massDeleteRooms() {
            if (!this.selectedRooms || this.selectedRooms.length === 0)
				return;
            try {
                let confirmMessage = '';
                let onboardingMessage = '';
                const anyOnboardingRooms: boolean = ((this as any).userOnboardStatus && !(this as any).userOnboardStatus.value.showDialog && (this as any).userOnboardStatus.value.isOnboarding && (this as any).userOnboardStatus.value.room && this.selectedRooms.map(x => x.id).includes((this as any).userOnboardStatus.value.room.id)) ?? false;

                if(anyOnboardingRooms) {
                    onboardingMessage = `${this.selectedRooms.length == 1 ? 'This' : 'A selected'} room was created using the Ditto setup tutorial. Deleting this room before completing the Ditto setup tutorial will cause the tutorial to restart from the beginning. `
                }

                if(this.selectedRooms.length === this.roomCount && this.selectedRooms.length > 1) {
                    confirmMessage = `Are you sure you want to delete all ${this.selectedRooms.length} rooms?`;
                }
                else if( this.selectedRooms.length === 1) {
                    confirmMessage = `Are you sure you want to delete this room?`;
                }
                else {
                    confirmMessage = `Are you sure you want to delete ${this.selectedRooms.length} of ${this.roomCount} rooms'?`;
                }
				await (this as any).$confirm(
					`${onboardingMessage ? onboardingMessage : ''}${confirmMessage}`,
					'Warning',
					{
						confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
					}
				);

                this.viewLoading = true;
                const roomDeletePromise = [];

				for (const room of this.selectedRooms) {
					roomDeletePromise.push(roomState.deleteRoom({roomId:room.id, locationId: room.location.id}));
				}

                const responses = await Promise.all(roomDeletePromise);

				const failureResponses = responses.filter((x) => !x.success);

                await tierState.fetchActivateReceiversCheck(true);

                if(anyOnboardingRooms) {
                    await onboardStatusState.end();
                    await this.createNewOnboarding();
                }

                const promises = [
                    this.loadReceiverList(),
                    this.doRoomSearch(false)
                ];

                await Promise.all(promises);

                if (failureResponses && failureResponses.length > 0) {
					for (const response of failureResponses) {
                        (this as any).$message.error(response.reason ? `Room delete failed: ${response.reason}` : 'Room delete failed');
					}
                    this.viewLoading = false;
					return;
				}

                (this as any).$message.success( `Room${this.selectedRooms.length == 1 ? '' : 's'} deleted`);
                this.viewLoading = false;
            }
            catch(e) {
                // Cancelled
            }
        },

        onCancelReceiverPicker() {
            this.receiverPickerVisible = false;
        },

        onCancelLocationPicker() {
            this.locationPickerVisible = false
        },

        roomReceiverStatus(status: number) : string {
            let roomRecStatus = '';
            switch(status) {
                case 1:
                    roomRecStatus = 'LEGACY';
                    break;
                case 2: 
                    roomRecStatus = 'OFFLINE';
                    break;
                case 3:
                    roomRecStatus = 'WARNING';
                    break;
                case 4:
                    roomRecStatus = 'ONLINE';
                    break;
            }

            return roomRecStatus;
        },

        handleFilterChange() {
            // this.viewLoading = true;
            this.handleCurrentPageChange(1);
        },

        handleSizeChange(pageSize: number) {
            this.paging.pageSize = pageSize;
            this.handleCurrentPageChange(1);
        },

        handleCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.paging.pageIndex = pg;
                this.doRoomSearch(false);
            this.viewLoading = true;
            }, 1000);            
        },

        async doRoomSearch(fromCreated: boolean ) {
            this.viewLoading = true;
            if(!fromCreated) {
                this.paging.locationId = undefined;
                this.paging.isActive = undefined;
                this.paging.status = undefined;
                this.paging.name = undefined;
                this.paging.roomCode = undefined;
            
                if(this.locationFilter) {
                    this.paging.locationId = this.locationFilter;
                }

                if(this.activeFilter) {
                    this.paging.isActive = this.activeFilter.toLowerCase() == 'yes';
                }

                if(this.statusFilter) {
                    this.paging.status = this.statusFilter;
                }

                if(this.roomSearch) {
                    this.paging.name = this.roomSearch;
                    this.paging.roomCode = this.roomSearch;
                }

            }

            const ssPaging = JSON.stringify(this.paging);
            sessionStorage.setItem('roompage', ssPaging);

            const storeRes = await roomState.fetchRoomDisplayList(this.paging)

            this.roomSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data : [] as IRoomListDisplay[];
            this.roomCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;

            if(this.paging.pageIndex > 1 && this.roomSearchResults.length == 0) {
                this.paging.pageIndex = this.paging.pageIndex - 1;
                this.doRoomSearch(false);
                return;
            } else {
                this.loadSignageLists();
                if(!fromCreated) this.viewLoading = false;
                return;
            }
        },

        async loadSignageLists() {
            if(this.roomSearchResults && this.roomSearchResults.length > 0) {
                const roomIds = this.roomSearchResults.map((x: any) => x.id);
                const storeResponse = await signageListState.fetchSignageListsForRooms(roomIds);
                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `Signage list retrival failed: ${storeResponse.reason}` : 'Signage list retrival failed');
                    return;
                }

                if(storeResponse.data) {
                    this.signageLists = storeResponse.data.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                }       
            } 
        },

        async loadLocationList() {
            const storeRes = await locationState.fetchLocationsDDL();

            if(!storeRes.success || !storeRes.data) return;

            const mappedData = storeRes.data.map(x => {
                return {
                    id: x.id,
                    name: x.parentId ? `-- ${x.name}` : x.name,
                    sortName: x.parentId ? `${x.parentName}-${x.name}`: x.name
                }
            })

            this.locationList = mappedData.sort((a,b) => SortService.Alphabetical(a.sortName, b.sortName));
        },

        async loadReceiverList(reload = true) {
            const storeRes = await receiverState.fetchReceiversDLL(reload);

            if(!storeRes.success || !storeRes.data) return;

            this.availableReceivers = storeRes.data.filter(x => !x.room);
        },

        onSignageListClick(row: any) {
            this.isSignageListModalVisible = true;
            this.signageListModalTitle = `Signage lists assigned to ${row.roomName}`;
            this.selectedRoomLists = this.signageListsForRoom(row.id);
        },
    },

    async created () {
        locationState = getModule(LocationState);
        roomState = getModule(RoomState);
        receiverState = getModule(ReceiverState);
        userState = getModule(UserState);
        organizationState = getModule(OrganizationState);
        onboardStatusState = getModule(OnboardStatusState);
        signageListState = getModule(SignageListState);
        tierState = getModule(TierState);

        if(sessionStorage.getItem('roompage')) {
            this.paging = JSON.parse(sessionStorage.getItem('roompage') as string) as IRoomModelSearch;
            this.activeFilter = '';

            if(this.paging.isActive != null) {
                this.activeFilter = this.paging.isActive ? 'Yes' : 'No';
            }

            this.locationFilter = this.paging.locationId as string;
            this.statusFilter = this.paging.status as string;
            this.roomSearch = this.paging.name as string;
        }

        await Promise.all([
            userState.fetchMe(null),
            onboardStatusState.fetchOnboardStatus(),
            this.doRoomSearch(true),
            this.loadLocationList(),
            this.loadReceiverList(),
            tierState.fetchActivateReceiversCheck(),
        ]);

        if(!userState.me) {
            console.error("Error getting user information.");
            return;
        }
       

        this.loadOrganization();
        this.viewLoading = false;
    }
});
