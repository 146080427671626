
import { getModule } from 'vuex-module-decorators';
import { IOrganization } from '../../store/interfaces/IOrganization';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import { defineComponent } from 'vue';
import PageDirtyService from '../../services/PageDirtyService';
import UserState, { IUserState } from '../../store/modules/users';
import { IUser } from '../../store/interfaces/IUser';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, IRoomListDisplay } from '../../store/interfaces/IRoom';
import LocationState, { ILocationState } from '../../store/modules/locations';
import { ILocation, ILocationListDisplay } from '../../store/interfaces/ILocation';
import shortid from 'shortid';
import OrgUsageStatBarGraphs from '../../components/OrgUsageStatBarGraphs.vue';
import readXlsxFile from 'read-excel-file';
import { IRoomModelSearch } from '../../store/interfaces/IModelSearch';
import { mdiAlertCircle, mdiCheckBold, mdiInformationOutline } from '@mdi/js';
import Icon from "../../components/Icon.vue";
import TierState, { ITierState } from '../../store/modules/tier';
import { ITier, ICheckTierChangePayload } from '../../store/interfaces/ITier';
import SubscriptionState, { ICalculateSubscriptionAdjustmentInput, IUpdateSubscriptionInput, ISubscriptionState } from '../../store/modules/subscriptions';
import moment from 'moment';
import RoleState, { IRoleState } from '../../store/modules/role';


let organizationState = {} as IOrganizationState;
let userState = {} as IUserState;
let roomState = {} as IRoomState;
let locationState = {} as ILocationState;
let tierState = {} as ITierState;
let subscriptionState = {} as ISubscriptionState;
let roleState = {} as IRoleState;

interface IAdminRoomDisplay extends IRoomListDisplay {
    roomDetails: IRoom;  
}

export default defineComponent ({
    name: 'OrganizationDetails',
    data() {
        return {
            activeTab: "overview" as string,
            organization: {} as IOrganization,
            refund: {
                refundAmount: null as number | null
            } as any,
            users: [] as IUser[],
            locations: [] as ILocationListDisplay[],
            roomsToBeCreated: [] as any[],
            errorRooms: [] as any,
            roomSearch: '' as string,
            roomSortField: '' as string,
            roomSortOrder: '' as string,
            searchDebounce: null as any,
            roomSearchResults: [] as IAdminRoomDisplay[],
            currentPage: 1 as number,
            fileName: '' as string,
            viewLoading: true,
            usersLoading: true,
            roomsLoading: false,
            roomsImporting: false,
            roomsUploading: false,
            importComplete: false,
            saving: false,
            showCounts: false,
            showProgress: false,
            roomUploadProgressPercentage: '0',
            roomCount: 0 as number,
            totalRoomsCount:0,
            roomsCreatedCount: 0,
            locationsCreatedCount: 0,
            subLocationsCreatedCount: 0,
            fileRoomCount: 0,
            errorsCount: 0,
            ogBillingType: '',
            mdiAlertCircle,
            mdiCheckBold,
            mdiInformationOutline,
            fileList: [],
            allTiers: [] as ITier[],
            orgTier: {} as ITier,
            currentPlanId: '' as string,
            currentBillingFrequency: '' as string,
            showChangeMessage: false as boolean,
            currentRefundAmount: '' as string,
            currentSubscription: {} as any,
            featureChangeList: [] as string[],
            restrictionsChangeList: [] as string[],
            newSubscriptionTotal: '' as string,
            latestInvoiceTotal: 0 as number,
            currentSubscriptionLimit: 0 as number,
            isPlanChangeConfirmationVisibile: false,
            isRefundModalVisibile: false,
            planDisabledText: 'Ditto plan does not include this feature.',
            rules:{
                'name':[{ required: true, message: 'Organization name is required.', trigger: 'change'}],
                'tierId':[{ required: true, validator: this.checkTierChange, trigger: 'change' }],
                'billing.billing_type':[{ validator: this.validateBillingType, trigger: 'change' }],
                'billing.subscription_limit':[{ validator: this.validateSubscriptionLimit, trigger: 'change' }],
                'billing.customer_id':[{ validator: this.validateStripeCustomerId, trigger: 'change' }],
                'billing.billingFrequency':[{required: true, validator: this.validateBillingFrequencyChange, trigger: 'change' }],
                'restrictions.restrictionUsers':[{ validator: this.validateUserOverride, trigger: 'change' }],
                'restrictions.restrictionReceivers':[{ validator: this.validateReceiverOverride, trigger: 'change' }],
                'restrictions.restrictionSignageItems':[{ validator: this.validateSignageItemOverride, trigger: 'change' }],
                'restrictions.restrictionStorageGBPerRec':[{ validator: this.validateStorageOverride, trigger: 'change' }]
            },
            refundRules: {
                'refundAmount':[{ validator: this.validateRefund, trigger: 'change'}]
            },
            roomColumns: [
                {
                    columnName: 'ROOM NAME',
                    allowedValues: '',
                    required: 'Yes'
                },
                {
                    columnName: 'ROOM CODE',
                    allowedValues: '',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE MIRRORING',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE SIGNAGE',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'SHOW CONNECTION INFO',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'CONNECTION LOCATION',
                    allowedValues: 'Top Left/Top Right/Bottom Left/Bottom Right',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE ALERTS',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'ENABLE MODERATOR CONTROLS',
                    allowedValues: 'Y/N',
                    required: 'No'
                },
                {
                    columnName: 'MODERATOR CONNECTION TYPE',
                    allowedValues: 'One Time/On Screen/Custom',
                    required: 'No'
                },
                {
                    columnName: 'MODERATOR PASSWORD',
                    allowedValues: '',
                    required: 'No'
                },
                {
                    columnName: 'LOCATION NAME',
                    allowedValues: '',
                    required: 'No'
                },
                {
                    columnName: 'SUB LOCATION NAME',
                    allowedValues: '',
                    required: 'No'
                }
            ]
        }
    },

    methods: {
        async validateForm (): Promise<boolean> {
            if((this.organization.billing.billing_type === 'Trial' && this.organization.tierId !== 'trial') || (this.organization.billing.billing_type !== 'Trial' && this.organization.tierId === 'trial')) {
                this.organization.billing.billing_type = 'Trial';
                this.organization.tierId = 'trial';
            }
            return new Promise((resolve) => {
                (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                    if(!valid) {
                        let errorHTML = '';
                        for(const key in obj) {
                            for(const err of obj[key]) {
                                errorHTML = `${errorHTML}${err.message}<br>`;
                            }
                        }
                        console.error(errorHTML);
                        (this as any).$message({
                            dangerouslyUseHTMLString: true,
                            message: errorHTML,
                            type: 'error'
                        });
                    }
                    resolve(valid);
                });
            });
        },

        async validatRefundForm (): Promise<boolean> {
            return new Promise((resolve) => {
                (this as any).$refs['refundForm'].validate((valid: boolean) => {
                    resolve(valid);
                });
            });
        },

        async validateStripeCustomerId(rule: any, value: any, callback: any)  {
            if(!this.organization.billing || this.organization.billing.billing_type != 'Stripe') {
                callback();
                return;
            }
            
            if(!value || value.length < 1) {
                callback('Stripe Customer Id required when billing type is Stripe');
                return;
            }

            callback();

        },

        async validateSubscriptionLimit(rule: any, value: any, callback: any)  {
            if(this.selectedTier.id == 'trial' && value == 0) {
                callback();
                return;
            }

            if(!value) {
                callback('Subscription limit is required.');
                return;
            }

            if(!Number.isInteger(value)){
                callback('Subscription limit must be a number value.');
                return;
            }

            if(value < tierState.orgTierUsage.receiverCount) {
                callback(`Subscription limit must be above or equal to the current active receiver count of ${tierState.orgTierUsage.receiverCount}.`);
                return;
            }

           if(this.organization.restrictions && this.organization.restrictions.restrictionReceivers && value > this.organization.restrictions.restrictionReceivers) {
                callback('Subscription limit should be less than the plan override limit for receivers.');
                return;
            }

            if((!this.organization.restrictions || !this.organization.restrictions.restrictionReceivers) && this.selectedTier.restrictionReceivers != 0 && value > this.selectedTier.restrictionReceivers) {
                callback('Subscription limit should be less than the plan limit for receivers.');
                return;
            }

            if(this.selectedTier.restrictionReceivers == -1 && value != this.selectedTier.restrictionReceivers) {
                callback('Subscription limit cannot be changed due to plan limit for receivers.');
                return;
            }

            this.newSubscriptionTotal = '';
            if(this.organization.billing.billing_type === 'Stripe' && this.currentSubscriptionLimit != value) {
                this.viewLoading = true;
                await this.previewChange();
                this.viewLoading = false;
            }
            
            callback();
        },

        async previewChange() {
            const payload = {
                customerId: this.organization.billing.customer_id,
                quantity: this.organization.billing.subscription_limit,
                tierId: this.selectedTier.stripeProductId,
                billingFrequency: this.organization.billing.billingFrequency
            } as ICalculateSubscriptionAdjustmentInput;

            const amount = await subscriptionState.calculateSubscriptionAdjustment(payload);
           
            if(amount.data) {
                this.newSubscriptionTotal = amount.data;
            }
        },

        async validateBillingType(rule: any, value: any, callback: any) {
            if(!value) {
                callback('Billing Type is required.');
                return;
            } 

            if(value === 'Trial') {
                this.organization.tierId = 'trial';
            }
           
            if(value !== 'Trial' && this.organization.tierId === 'trial') {
                callback('Billing Type must be Trial when Ditto Plan is Trial.');
                return;
            }

            if(value !== 'Trial' && this.organization.tierId !== 'trial') {
                (this as any).$refs['form'].clearValidate();
            }

            callback();
            return;
        },

        async checkTierChange(rule: any, value: any, callback: any) {
            if(!value) {
                callback('Ditto Plan is required.');
                return;
            } 

            if(value === 'trial') {
                this.organization.billing.billing_type = 'Trial';
            }

            if(this.organization.billing.billing_type === 'Trial' && value !== 'trial') {
                callback('Ditto Plan must be Trial when Billing Type is Trial.');
                return;
            }

            if(this.organization.billing.billing_type !== 'Trial' && value !== 'trial') {
                (this as any).$refs['form'].clearValidate();
            }

            this.newSubscriptionTotal = '';
            this.showChangeMessage = false;
            this.featureChangeList = [];
            this.restrictionsChangeList = [];
            if(value == this.currentPlanId) { 
                this.viewLoading = false; 
                callback();
                return; 
            }

            this.viewLoading = true;
            const currentPlan = this.allTiers.find((x: any) => x.id == this.currentPlanId)

            let payload = {
                organization: this.organization,
                oldTier: currentPlan as ITier,
                newTier: this.selectedTier,
                newSubscriptionLimit: this.organization.billing.subscription_limit
            } as ICheckTierChangePayload
            
            const results = await tierState.checkTierChange(payload);
            
            this.showChangeMessage = results.data.allowChange;
            this.featureChangeList = results.data.featureUsedIds;
            this.restrictionsChangeList = results.data.overRestrictionsIds;
            if(!results.data.allowChange) {
                this.viewLoading = false;   
                callback('Unable to change Ditto plan. Reasons highlighted below.');
                return;
            } 

            if(this.organization.billing.billing_type === 'Stripe' && this.selectedTier.id != 'trial' && this.selectedTier.id != this.currentPlanId) {
                await this.previewChange();
            }
        
            this.viewLoading = false;
            callback();    
            return;      
        },

        async validateBillingFrequencyChange(rule: any, value: any, callback: any) {
            if(this.organization.billing.billing_type === 'Stripe' && this.organization.tierId && !value) {
                callback('Billing frequency is required.');
                return;
            }

            this.newSubscriptionTotal = '';
            this.viewLoading = true;
           
            if(this.organization.billing.billing_type === 'Stripe' && this.organization.billing.billingFrequency != this.currentBillingFrequency) {
                await this.previewChange();
            }

            this.viewLoading = false;
            callback();    
        },

        validateUserOverride(rule: any, value: any, callback: any) {
            if(!value) { 
                if(this.selectedTier.restrictionUsers!=0 && this.selectedTier.restrictionUsers < tierState.orgTierUsage.userCount) {
                    return callback(`Current user count of ${tierState.orgTierUsage.userCount} is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(!Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }
            
            if(value < tierState.orgTierUsage.userCount) {
                return callback(`Override must be below or equal to the current user count of ${tierState.orgTierUsage.userCount}.`);
            }

            callback();
        },

        validateReceiverOverride(rule: any, value: any, callback: any) {
            if(!value) { 
                if(this.selectedTier.restrictionReceivers != 0 && this.selectedTier.restrictionReceivers < tierState.orgTierUsage.receiverCount) {
                    return callback(`Current receiver count of ${tierState.orgTierUsage.receiverCount} is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(!Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }

            if(value < tierState.orgTierUsage.receiverCount) {
                return callback(`Override must be above or equal to the current active receiver count of ${tierState.orgTierUsage.receiverCount}.`);
            }
            
            callback();
        },

        validateSignageItemOverride(rule: any, value: any, callback: any) {
            if(!value) { 
                if(this.selectedTier.restrictionSignageItems!= 0 && this.selectedTier.restrictionSignageItems < tierState.orgTierUsage.signageItemCount) {
                    return callback(`Current signage item count of ${tierState.orgTierUsage.signageItemCount} is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(!Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }

            if(value < tierState.orgTierUsage.signageItemCount) {
                return callback(`Override must be above or equal to the current signage item count of ${tierState.orgTierUsage.signageItemCount}.`);
            }
            
            callback();
        },

        validateStorageOverride(rule: any, value: any, callback: any) {
            if(value && !Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }
            
            const usage = tierState.orgTierUsage.storageUsedGB ? Number(tierState.orgTierUsage.storageUsedGB) : 0;
            
            if(!value) { 
                const totalAllowedStorage = (this.orgTier.restrictionStorageGBPerRec) * this.organization.billing.subscription_limit;
                if(totalAllowedStorage!= 0 && totalAllowedStorage < usage) {
                    return callback(`'Current storage usage of ${ usage.toFixed(2)} GB is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(value * this.organization.billing.subscription_limit < usage) {
                return callback(`Override must be above or equal to the current storage of ${usage.toFixed(2)} GB.`);
            }
            
            callback();
        },

        validateRefund(rule: any, value: any, callback: any) {
            if(!value) { return callback(); }

            if(!Number(value)){
                callback('Refund must be a number value.');
                return;
            }

            if(value > this.currentRefundAmount) {
                return callback('Refund cannot be larger than available refund.');
            }

            if(value > this.latestInvoiceTotal) {
                return callback('Refund cannot be larger than charge from latest invoice.');
            }

            if (!value.includes('.') || value.indexOf(".") >= 0) {
                const decimalPos = value.indexOf(".");
                let rightSide = value.substring(decimalPos+1);
     
                if(rightSide.length != 2) {
                    return callback('Refund amount must have 2 decimal places.');
                }
            }
            else {
                this.refund.refundAmount = Number(this.refund.refundAmount.toFixed(2));
            }

            callback();
        },

        async onSubmitClick() {
            if(this.ogBillingType != this.organization.billing.billing_type || this.currentPlanId != this.organization.tierId) {
                if((this.organization.billing.billing_type != 'Trial' && this.organization.tierId == 'trial') || (this.organization.billing.billing_type == 'Trial' && this.organization.tierId != 'trial')) {
                    await (this as any).$confirm(`Both Billing Type and Ditto Plan will be set to Trial.`, 'Warning', {
                        confirmButtonText: 'Ok',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                }
            }

            this.viewLoading = true;

            if(!await this.validateForm()) { return; }

            if(this.totalChangeText) {
                this.isPlanChangeConfirmationVisibile = true;
                return;
            }
            else {
                await this.onSubmit();
            }

            this.viewLoading = false;
        },

        async onSubmit() {
            //if the original plan was stripe but it's being changed to PO or Trial, cancel Stripe subscription
            if(this.ogBillingType == 'Stripe' && this.organization.billing.billing_type != 'Stripe') {
                await (this as any).$confirm(`Changing billing type to ${this.organization.billing.billing_type} will cancel Stripe subscription at the end of the billing cycle?`, 'Warning', {
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                if(this.currentSubscription) {
                    const cancelSubscriptionResponse = await subscriptionState.cancelSubscription(this.currentSubscription.id);
                
                    if(!cancelSubscriptionResponse.success) {
                        (this as any).$message.error("Canceling Subscription Failed", cancelSubscriptionResponse.reason);
                        this.saving = false;
                        this.viewLoading = false;
                        return;
                    }
                }
            }

            this.saving = true;
            this.viewLoading = true;

            //update stripe subscription if there is a new tier or billing frequency changes
             if(this.organization && this.organization.billing && this.organization.billing.billing_type === 'Stripe' && 
             (this.selectedTier.id != this.currentPlanId || 
             this.organization.billing.billingFrequency != this.currentBillingFrequency || 
             this.organization.billing.subscription_limit != this.currentSubscriptionLimit)) {
                
                const payload = {
                    customerId: this.organization.billing.customer_id,
                    tier: this.selectedTier,
                    quantity: this.organization.billing.subscription_limit,
                    organization: this.organization,
                    calculatedTotal: this.newSubscriptionTotal,
                    billingFrequency: this.organization.billing.billingFrequency
                } as IUpdateSubscriptionInput;
               
                const subscriptionResponse = await subscriptionState.updateSubscription(payload);

                if(!subscriptionResponse.success) {
                    (this as any).$message.error("Subscription Update Failed", subscriptionResponse.reason);
                    this.saving = false;
                    this.viewLoading = false;
                    return;
                }

                if(subscriptionResponse.data) {
                    const updatedSubscription = subscriptionResponse.data.find((x: any) => x.id == this.currentSubscription.id);
                    if(updatedSubscription && updatedSubscription.stripeRefundAmount) {
                        this.organization.billing.stripeRefundAmount = updatedSubscription.stripeRefundAmount;
                    }
                }
            }

            // Remove overrides is not a PO
            if(!this.isPO && this.organization.restrictions) {
                this.organization.restrictions = {
                    restrictionReceivers: null as any,
                    restrictionUsers: null as any,
                    restrictionSignageItems: null as any,
                    restrictionStorageGBPerRec: null as any
                }
            }

            // Make sure is_active is true when changing to PO
            if(this.ogBillingType != 'PO' && this.isPO){
                this.organization.billing.is_active = true;
            }
            
            const storeResponse = await organizationState.updateOrganization(this.organization);
            if(!storeResponse.success) {
                (this as any).$message.error("Update Failed", storeResponse.reason);
                this.saving = false;
                this.viewLoading = false;
                return;
            }

            const loadOrgsResponse = await organizationState.fetchOrganizations(this.$route.params.id as string);
            if(!loadOrgsResponse.success) {
                this.viewLoading = false;
                this.organization = {} as IOrganization;
                return;
            }

            this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
            this.ogBillingType = this.organization.billing.billing_type;
           
            await tierState.fetchTier(this.organization.id);
            this.orgTier = tierState.orgTier;

            this.currentPlanId = this.organization.tierId;
            this.currentBillingFrequency = this.organization.billing.billingFrequency;
            this.currentSubscriptionLimit = this.organization.billing.subscription_limit;
            this.currentRefundAmount = this.organization.billing.stripeRefundAmount && Number(this.organization.billing.stripeRefundAmount.replace('$', '')) ? Number(this.organization.billing.stripeRefundAmount.replace('$', '')).toFixed(2) : Number(0.00).toFixed(2);
            
            (this as any).$message.success('Organization updated.');

            this.saving = false;
            this.viewLoading = false;
            this.isPlanChangeConfirmationVisibile = false;
            this.newSubscriptionTotal = '';
        },

        async onCancel() {
            await (this as any).$confirm('Are you sure you wish to undo org changes?', 'Warning', {
                confirmButtonText: 'Undo Changes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });
            this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
            this.ogBillingType = this.organization.billing.billing_type
        },

        createPlanOverrides() {
            this.organization.restrictions = {
                restrictionReceivers: null as any,
                restrictionUsers: null as any,
                restrictionSignageItems: null as any,
                restrictionStorageGBPerRec: null as any
            }
        },

        createDeploymentProperties() {
            this.organization.deployment = {
                autoCreateRooms: false,
                autoLinkRooms: false,
                exposeAutoDeploymentOptions: false,
                deploymentCode: shortid.generate().toUpperCase()
            } as any;
        },

        handleCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = pg;
                this.doRoomSearch();
            this.roomsLoading = true;
            }, 1000);            
        },

        handleSearchChange() {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = 1;
                this.doRoomSearch();
            this.roomsLoading = true;
            }, 1000);            
        },

        handleSortChange(sort: any) {
            this.roomSortField = sort.prop;
            this.roomSortOrder = sort.order;
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.currentPage = 1;
                this.doRoomSearch();
            this.roomsLoading = true;
            }, 1000);            
        },

        doRoomSearch() {
            const roomSearchObj: IRoomModelSearch = {
                pageIndex:this.currentPage, 
                pageSize:10, 
                organizationId:this.organization.id
            };

            if(this.roomSearch != '') {
                const formatedSearch = this.roomSearch.replace('#', '');
                roomSearchObj.name = formatedSearch;
            }

            if(this.roomSortField && this.roomSortOrder) {
                roomSearchObj.sortField = this.roomSortField;

                let tempRoomSortOrder: 'asc' | 'desc' = 'asc';
                if(this.roomSortOrder == 'descending') tempRoomSortOrder = 'desc';

                roomSearchObj.sortOrder = tempRoomSortOrder;
            }

            roomState.fetchRoomDisplayList(roomSearchObj).then(storeRes => {
                this.roomSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data as IAdminRoomDisplay[] : [] as IAdminRoomDisplay[];
                this.roomCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
                this.roomsLoading = false;
            });
        },

        clearFile() {
            this.roomsToBeCreated = [];
            this.fileRoomCount = 0;
            this.fileName = '';
            this.showCounts = false;
            this.errorsCount = 0;
        },

        async onFileChange(file: File) {
            this.roomsImporting = true;
            this.roomsToBeCreated = [];
            this.fileRoomCount = 0;
            this.fileName = file.name;
            this.showCounts = false;

            const schema = {
                'ROOM NAME' : {
                    prop: 'roomName',
                    type: String 
                },

                'ROOM CODE' : {
                    prop: 'roomCode',
                    type: String 
                },

                'ENABLE MIRRORING' : {
                    prop: 'enableMirroring',
                    type: String 
                },

                'ENABLE SIGNAGE' : {
                    prop: 'enableSignage',
                    type: String 
                },

                'SHOW CONNECTION INFO' : {
                    prop: 'showConnectionInfo',
                    type: String 
                },

                'CONNECTION LOCATION' : {
                    prop: 'connectionInfoPosition',
                    type: String 
                },

                'ENABLE ALERTS' : {
                    prop: 'enableAlerts',
                    type: String 
                },

                'ENABLE MODERATOR CONTROLS' : {
                    prop: 'enableModeratorControls',
                    type: String 
                },

                'MODERATOR CONNECTION TYPE' : {
                    prop: 'moderatorConnectionType',
                    type: String 
                },

                'MODERATOR PASSWORD' : {
                    prop: 'moderatorPassword',
                    type: String 
                },

                'LOCATION NAME' : {
                    prop: 'locationName',
                    type: String 
                },

                'SUB LOCATION NAME' : {
                    prop: 'subLocationName',
                    type: String 
                }
            }

            let xlsxfile = file;

            const readFile = await readXlsxFile(xlsxfile, {schema});
            if(readFile.errors.length == 0) {
                this.roomsToBeCreated = readFile.rows;
            }

            this.fileRoomCount = this.roomsToBeCreated.length;

            this.roomsImporting = false;
        },

        convertYNToBoolean(value: string) {
            if(!value) { return undefined; }

            if(value.toLowerCase() == 'y') {
                return true;
            }

            if(value.toLowerCase() == 'n') {
                return false;
            }
            
            return undefined;
        },

        formatConnectionPosition(position: string) {
            if(!position) { return undefined; }

            if(position.toLowerCase() == 'top left') {
                return 'topLeft';
            }

            if(position.toLowerCase() == 'top right') {
                return 'topRight';
            }

            if(position.toLowerCase() == 'bottom left') {
                return 'bottomLeft';
            }

            if(position.toLowerCase() == 'bottom right') {
                return 'bottomRight';
            }

            if(position.toLowerCase() == 'topleft') {
                return 'Top Left';
            }

            if(position.toLowerCase() == 'topright') {
                return 'Top Right';
            }

            if(position.toLowerCase() == 'bottomleft') {
                return 'Bottom Left';
            }

            if(position.toLowerCase() == 'bottomright') {
                return 'Bottom Right';
            }

            return undefined;
        },

        formatModeratorType(type: string) {
            if(!type) { return undefined; }

            if(type.toLowerCase() == 'one time') {
                return 'onScreenOneTime';
            }

            if(type.toLowerCase() == 'on screen') {
                return 'onScreen';
            }

            if(type.toLowerCase() == 'custom') {
                return 'password';
            }

            if(type.toLowerCase() == 'onscreenonetime') {
                return 'One-time onscreen code';
            }

            if(type.toLowerCase() == 'onscreen') {
                return 'Onscreen code';
            }

            if(type.toLowerCase() == 'password') {
                return 'Custom password';
            }

            return undefined;
        },

        async loadRooms() {
            this.roomsLoading = true;

            await roomState.fetchRoomDisplayList({pageIndex:1, pageSize:10, organizationId:this.organization.id}).then((storeRes: any) => {
                this.roomSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data as IAdminRoomDisplay[] : [] as IAdminRoomDisplay[];
                this.roomCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
            })

            this.roomsLoading = false;
        },

        async loadRoomDetails(row: any, expandedRows: any) {
            if(expandedRows.length > 0) {
                const expandedRowIndex = this.roomSearchResults.findIndex(x => x.id == row.id);
                if(!this.roomSearchResults[expandedRowIndex].roomDetails) {
                    const roomDetails = (await roomState.fetchRoom({id: row.id as string, reload: true})).data;
                    this.roomSearchResults[expandedRowIndex].roomDetails = roomDetails ? roomDetails : {} as IRoom;
                }
            }
        },

        async loadLocations() {
            const response = await locationState.fetchLocationDisplayList({pageIndex:1, pageSize: 10000, organizationId: this.organization.id});
            this.locations = (response && response.success && response.data && response.data.data) ? response.data.data : [] as ILocationListDisplay[];
        },

        async onCreateRooms() {
            this.roomCount = 0;
            this.roomsCreatedCount = 0;
            this.locationsCreatedCount = 0;
            this.subLocationsCreatedCount = 0;
            this.errorsCount = 0;
            this.errorRooms = [];
            this.roomsUploading = true;
            this.totalRoomsCount = this.roomsToBeCreated.length;
            this.showCounts = true;
            this.showProgress = true;

            await this.loadLocations();

            for(const room of this.roomsToBeCreated) {
                let location = {} as ILocation;

                const matchingLocations: ILocationListDisplay[] = this.locations.filter((x:any) => x.name?.toLowerCase() == room.locationName?.toLowerCase());
                const matchingSubLocations: ILocationListDisplay[] = this.locations.filter((x:any) => x.name?.toLowerCase() == room.subLocationName?.toLowerCase());
         
                if(room.subLocationName){
                    if(!room.locationName) {
                        room.errorMsg = 'Cannot create a room that has a sub location but no location.'
                        this.errorRooms.push(room);
                        this.errorsCount++;
                    }

                    const matchingLocationIds = matchingLocations.map((loc:any) => loc.id);
                    const matchingSubLocation = matchingSubLocations.find((x:any) => x.name?.toLowerCase() == room.subLocationName?.toLowerCase() && matchingLocationIds.includes(x.parentLocation.id));
                    
                    if(matchingSubLocation) {
                        location = {id:matchingSubLocation.id, type:'Location'} as ILocation;
                    }
                    else {
                        const matchingLocation = matchingLocations.find((x:any) => x.name?.toLowerCase() == room.locationName?.toLowerCase());
        
                        if(matchingLocation)
                        {
                            const newSubLocation = {
                                name: room.subLocationName,
                                parentLocation: {id: matchingLocation.id, type:'Location'} as ILocation,
                                organization: this.organization
                            } as ILocation;

                            (newSubLocation as any).parentId = matchingLocation.id;

                            const locationResponse = await locationState.createLocation(newSubLocation);
                            if(locationResponse.success && locationResponse.data) {
                                location = locationResponse.data;
                                await this.loadLocations();
                                this.subLocationsCreatedCount++;
                            }
                        }
                        else {
                            const newLocation = {
                                name: room.locationName,
                                parentLocation: undefined,
                                organization: this.organization
                            } as ILocation;

                            const locationResponse = await locationState.createLocation(newLocation);
                            if(locationResponse.success && locationResponse.data) {
                                this.locationsCreatedCount++;
                                
                                const newSubLocation = {
                                    name: room.subLocationName,
                                    parentLocation: locationResponse.data,
                                    organization: this.organization
                                } as ILocation;

                                (newSubLocation as any).parentId = locationResponse.data.id;

                                const subLocationResponse = await locationState.createLocation(newSubLocation);
                                if(subLocationResponse.success && subLocationResponse.data) {
                                    location = subLocationResponse.data;
                                    await this.loadLocations();
                                    this.subLocationsCreatedCount++;
                                }
                            }
                        }
                    }
                }
                else {
                    if(room.locationName){
                        const matchingLocation = matchingLocations.find((x:any) => x.name?.toLowerCase() == room.locationName?.toLowerCase());
                        
                        if(matchingLocation) {
                            location = {id: matchingLocation.id, type: 'Location'} as ILocation;
                        }
                        else {
                            const newLocation = {
                                name: room.locationName,
                                parentLocation: undefined,
                                organization: this.organization
                            } as ILocation;

                            const locationResponse = await locationState.createLocation(newLocation)
                            if(locationResponse.success && locationResponse.data) {
                                location = locationResponse.data;
                                await this.loadLocations();
                                this.locationsCreatedCount++;
                            }
                        }
                    }
                }

                if(!this.errorRooms.includes(room)){
                    const newRoom = {
                        roomName: room.roomName,
                        roomCode: room.roomCode,
                        organization: this.organization,
                        enableMirroring: this.convertYNToBoolean(room.enableMirroring),
                        enableSignage: this.convertYNToBoolean(room.enableSignage),
                        showConnectionInfo: this.convertYNToBoolean(room.showConnectionInfo),
                        connectionInfoPosition: this.formatConnectionPosition(room.connectionInfoPosition),
                        enableAlerts: this.convertYNToBoolean(room.enableAlerts),
                        enableModeratorControls: this.convertYNToBoolean(room.enableModeratorControls),
                        moderatorConnectionType: this.formatModeratorType(room.moderatorConnectionType),
                        moderatorPassword: room.moderatorPassword,
                        location: location
                    } as IRoom;

                    const storeResponse = await roomState.createRoom(newRoom);
                    if(!storeResponse.success) {
                        room.errorMsg = storeResponse.reason ? storeResponse.reason : "Error creating room.";
                        this.errorRooms.push(room);
                        this.errorsCount++;
                    }
                    else {
                        this.roomsCreatedCount++;
                    }

                    this.roomUploadProgressPercentage = (((this.roomsCreatedCount + this.errorsCount)/this.totalRoomsCount) * 100).toFixed(0);
                }
            }

            this.roomsToBeCreated = this.errorRooms;

            //update rooms after creation is complete
            this.loadRooms();
            this.fileName = '';
            this.fileRoomCount = 0;
            this.roomsUploading = false;
            this.showProgress = false;

            if(this.errorsCount == 0){
                this.showCounts = false;
            }
        },

        formatTierRestriction(restriction: number): string {
            if(restriction == 0) {
                return 'unlimited'
            }

            if(restriction == -1) {
                return 'no new'
            }

            return restriction ? restriction.toString() : '';
        },

        checkFeature(id: string): boolean {
            if(id && this.featuresUsed) {
                const check = this.featuresUsed.find((x: any) => x.id == id);
                if(check){
                    return true;
                }
                return false;
            }
            return false;
        },

        tableRowClassName(obj: any) {
            if((this.featureChangeList.length > 0 && this.featureChangeList.includes(obj.row.id))
            || (this.restrictionsChangeList.length > 0 && this.restrictionsChangeList.includes(obj.row.id))) {
                return 'danger-row';
            }

            return '';
        }, 

        onRefundClick() {
            this.isRefundModalVisibile = true;
        },

        async onRefund() {
            if(!this.refund.refundAmount) { return; }

            this.saving = true;

            if(!await this.validatRefundForm()) return;

            const payload = {
                customerId: this.organization.billing.customer_id,
                organizationId: this.organization.id,
                refundAmount: this.refund.refundAmount
            }

            const refundResponse = await subscriptionState.refundSubscription(payload);

            if(!refundResponse.success) {
                (this as any).$message.error("Subscription refund failed", refundResponse.reason);
                this.saving = false;
                return;
            }

            this.refund.refundAmount = null;
            this.saving = false;
            this.isRefundModalVisibile = false;
        },

        getRoleName(id: string) {
            if(!roleState || !roleState.roles) return "";
            
            const role = roleState.roles.find((x:any) => x.id == id);
            
            return role && role.name ? role.name : '';
        }
    },

    computed: {
        showUploadInstructions(): boolean {
            if(this.fileName || this.errorsCount > 0) {
                return false;
            }
            return true;
        },

        selectedTier(): ITier {
            const selectedTier = this.allTiers.find(x => x.id == this.organization.tierId);
            return selectedTier ? selectedTier : {} as ITier;
        },

        usedStorageToSize() {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            let bytes = tierState.orgTierUsage.storageUsedBytes;

            if (bytes === 0) return '0'
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },

        orgLimitUsage(): any {
            const userLimit = !this.orgTier.restrictionUsers && this.orgTier.restrictionUsers != 0 ? 'NA' : this.orgTier.restrictionUsers == 0 ? 'Unlimited' : this.orgTier.restrictionUsers;
            const receiverLimit = !this.orgTier.restrictionReceivers && this.orgTier.restrictionReceivers != 0 ? 'NA' : this.orgTier.restrictionReceivers == 0 ? 'Unlimited' : this.orgTier.restrictionReceivers;
            const signageLimit = !this.orgTier.restrictionSignageItems && this.orgTier.restrictionSignageItems != 0 ? 'NA' : this.orgTier.restrictionSignageItems == 0 ? 'Unlimited' : this.orgTier.restrictionSignageItems;
            const subscriptionLimit = this.organization.billing.subscription_limit ? this.organization.billing.subscription_limit : 0;
            const storageLimit = !this.orgTier.restrictionStorageGBPerRec && subscriptionLimit != 0 ? 'NA' : subscriptionLimit == 0 ? 'Unlimited' : `${this.orgTier.restrictionStorageGBPerRec * subscriptionLimit} GB`;

            return [{
                id: 'restrictionUsers',
                limitName: 'Number of Users',
                limit: `${tierState.orgTierUsage.userCount}/${userLimit}`
            },
            {
                id: 'restrictionReceivers',
                limitName: 'Number of Receivers',
                limit: `${tierState.orgTierUsage.receiverCount}/${receiverLimit}`
            },
            {
                id: 'restrictionSignageItems',
                limitName: 'Number of Signage Items',
                limit: `${tierState.orgTierUsage.signageItemCount}/${signageLimit}`
            },
            {
                id: 'restrictionStorageGBPerRec',
                limitName: 'Storage',
                limit: `${this.usedStorageToSize}/${storageLimit}`
            }]
        },

        featuresUsed() {
            return tierState.orgTierUsage.featuresUsed;
        },

        userControlledFeatures() {
            return tierState.featuresList.filter((x: any) => x.userControlled == true && x.tiers.some((y: any) => y.id == this.currentPlanId));
        },

        currentPlanName(): string {
            const currentTier = this.allTiers.find((x: any) => x.id == this.currentPlanId);
            return currentTier ? currentTier.name : '';
        },

        totalChangeText(): string {
            let dayMessage = '';
            let totalMessage = '';
            if(this.newSubscriptionTotal.includes("-") 
                && moment(this.organization.billing.expiration_date).subtract(30, 'days') > moment()
                && this.organization.billing.billing_type == 'Stripe' 
                && (this.organization.billing.billingFrequency != this.currentBillingFrequency
                || this.organization.billing.subscription_limit != this.currentSubscriptionLimit
                || this.organization.tierId != this.currentPlanId)) {
                dayMessage = 'overrides the 30-day change window';
            }
            
            if(this.newSubscriptionTotal) {
                if(this.newSubscriptionTotal.includes("-")) {
                    totalMessage = `creates a refund available amount of ${this.newSubscriptionTotal.replace('-', '')}`;
                }
                else {
                    totalMessage = `causes an immediate charge amount of ${this.newSubscriptionTotal}`;
                }
            }

            if(dayMessage && totalMessage) {
                return `Saving changes ${dayMessage} and ${totalMessage}.`;
            }
            if(dayMessage && !totalMessage) {
                return `Saving changes ${dayMessage}.`;
            }
            if(!dayMessage && totalMessage) {
                return `Saving changes ${totalMessage}.`;
            }
            return '';
        },

        stripeCustomerUrl(): string {
            if(this.organization && this.organization.billing && this.organization.billing.customer_id) {
                return `${ process.env.VUE_APP_STRIPE_CUSTOMER_URL }${this.organization.billing.customer_id}`;
            }
            return '';
        },

        isPO(): boolean {
            return this.organization.billing.billing_type === 'PO';
        },

        showDaily(): boolean {
            const tier = this.allTiers.find((x: any) => x.id == this.organization.tierId);
            if(tier && tier.stripeProductId) {
                const hasDaily = subscriptionState.allSubscriptionPlans.some((x: any) => x.id == tier.stripeProductId && x.pricing.some((y: any) => y.recurring.interval == 'day'));
                return hasDaily;
            }
            return false;
        }
    },

    async created() {
        organizationState = getModule(OrganizationState);
        userState = getModule(UserState);
        roomState = getModule(RoomState);
        locationState = getModule(LocationState);
        tierState = getModule(TierState);
        subscriptionState = getModule(SubscriptionState);
        roleState = getModule(RoleState);

        const loadOrgsResponse = await organizationState.fetchOrganizations(this.$route.params.id as string);
        if(!loadOrgsResponse.success) {
            this.viewLoading = false;
            this.organization = {} as IOrganization;
            return;
        }
        this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
        this.ogBillingType = this.organization.billing.billing_type;

        await Promise.all([
            tierState.fetchAllTiersList(),
            tierState.fetchTier(this.organization.id),
            tierState.fetchTierUsage(this.organization.id),
            tierState.fetchAllFeaturesList(),
            roleState.fetchRoles(this.organization.id),
            subscriptionState.fetchSubscriptionPlans()
        ]);

        if(this.organization && this.organization.billing && this.organization.billing.customer_id) {
            const sub = await subscriptionState.fetchSuperSubscriptions(this.organization.billing.customer_id);

            if(sub.data) {
                this.currentSubscription = sub.data[0];
                
                if(this.currentSubscription && this.currentSubscription.latest_invoice) {
                    const inv = await subscriptionState.fetchInvoice(this.currentSubscription.latest_invoice);
                    this.latestInvoiceTotal = Number((inv.data.total/100).toFixed(2));
                }
               
            }
        }
        
        this.currentPlanId = this.organization.tierId;
        this.currentBillingFrequency = this.organization.billing.billingFrequency;
        this.currentSubscriptionLimit = this.organization.billing.subscription_limit;
        this.currentRefundAmount = this.organization.billing.stripeRefundAmount && Number(this.organization.billing.stripeRefundAmount.replace('$', '')) ? Number(this.organization.billing.stripeRefundAmount.replace('$', '')).toFixed(2) : Number(0.00).toFixed(2);
        this.allTiers = tierState.tiersList;
        this.orgTier = tierState.orgTier;
      
        this.viewLoading = false;
        const userSearchResults = await userState.searchUsers({pageIndex:1, pageSize: 1000, organizationId: this.$route.params.id as string})
        if(!userSearchResults || !userSearchResults.data || !userSearchResults.data.data) return;
        this.users = userSearchResults.data.data;
        this.usersLoading = true;

        await this.loadRooms();
    },

    components: {
        OrgUsageStatBarGraphs,
        Icon   
    },

    watch: {
        "$route.params.id"(value) {
            if(value && this.$route.path.includes('admin/organizations')) {
                organizationState.fetchOrganizations(this.$route.params.id as string).then(()=> {
                    this.ogBillingType = this.organization.billing.billing_type
                    this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
                    this.ogBillingType = this.organization.billing.billing_type
                    userState.searchUsers({pageIndex:1, pageSize: 1000, organizationId: this.$route.params.id as string}).then(userSearchResults => {
                        if(!userSearchResults || !userSearchResults.data || !userSearchResults.data.data) return;
                        this.users = userSearchResults.data.data;
                    });
                }).catch(() => {
                    this.viewLoading = false;
                    this.organization = {} as IOrganization;
                });
            }
        },
        organization: {
            handler (e) {
                const origOrg = organizationState.getOrganizationById(this.$route.params.id as string);

                PageDirtyService.monitorObjectSet([{defaultObject: origOrg, mutatedObject: e}]);
            },
            deep: true
        }
    }
})
