
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'
import UserState, { IUserState } from '../../store/modules/users';
import AuthService  from '../../auth/authService';
import { IUserRegistrationModel } from '../../store/interfaces/IUserRegistrationModel';

let userState ={} as IUserState;

export default defineComponent({
  name: "SignupView",
  data() {
      const validatePhone = (rule: any, value: string, callback: any) => { 
        const _thisNewUser = this as any;
        if (value !== '' ) {
            if(value.length < 10) {
                callback(new Error('Please specify a valid phone number.'));
            }
            else {
                if(_thisNewUser.newUser.country == 'US') {
                    const phone_number = value.replace(/\s+/g, "");
                    if(phone_number.length > 9 && phone_number.match(/^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/))
                    {
                        callback();
                    }
                    else {
                        callback(new Error('Please specify a valid phone number.'));
                    }
                }
                else {
                    callback();
                }
            }
        }
      };
      const validateTerms = (rule: any, value: string, callback: any) => {
        const _this = this as any;
        if (!_this.acceptTerms) {
              callback(new Error('You must accept the Terms and Conditions to continue.'));
          }
          else {
              callback();
          }
      };
      const validateEmail = (rule: any, value: string, callback: any) => {
          const _this = this as any;
          if (_this.invalidEmail) {
              callback(new Error('Please specify a valid email address.'));
          }
          else {
              callback();
          }
      };
      return {
        showAirParrotMessage: false,
        submittingForm: false,
        viewLoading: false,
        signupAllowed: false,
        invalidEmail: false,
        errorMessage: '',
        acceptTerms: false,
        newUser: {
            firstName: '',
            lastName: '',
            company: '',
            jobFunction: '',
            country: '',
            state: '',
            market: '',
            companySize: '',
            dittoUsage: '',
            numberDisplays: '',
            email: '',
            phone: ''
        },
        allCountries: [
            { name: 'Afghanistan', id: 'AF' },
            { name: 'Aland Islands', id: 'AX' },
            { name: 'Albania', id: 'AL' },
            { name: 'Algeria', id: 'DZ' },
            { name: 'Andorra', id: 'AD' },
            { name: 'Angola', id: 'AO' },
            { name: 'Anguilla', id: 'AI' },
            { name: 'Antarctica', id: 'AQ' },
            { name: 'Antigua and Barbuda', id: 'AG' },
            { name: 'Argentina', id: 'AR' },
            { name: 'Armenia', id: 'AM' },
            { name: 'Aruba', id: 'AW' },
            { name: 'Australia', id: 'AU' },
            { name: 'Austria', id: 'AT' },
            { name: 'Azerbaijan', id: 'AZ' },
            { name: 'Bahamas', id: 'BS' },
            { name: 'Bahrain', id: 'BH' },
            { name: 'Bangladesh', id: 'BD' },
            { name: 'Barbados', id: 'BB' },
            { name: 'Belarus', id: 'BY' },
            { name: 'Belgium', id: 'BE' },
            { name: 'Belize', id: 'BZ' },
            { name: 'Benin', id: 'BJ' },
            { name: 'Bermuda', id: 'BM' },
            { name: 'Bhutan', id: 'BT' },
            { name: 'Bolivia, Plurinational State of', id: 'BO' },
            { name: 'Bonaire, Sint Eustatius and Saba', id: 'BQ' },
            { name: 'Bosnia and Herzegovina', id: 'BA' },
            { name: 'Botswana', id: 'BW' },
            { name: 'Bouvet Island', id: 'BV' },
            { name: 'Brazil', id: 'BR' },
            { name: 'British Indian Ocean Territory', id: 'IO' },
            { name: 'Brunei Darussalam', id: 'BN' },
            { name: 'Bulgaria', id: 'BG' },
            { name: 'Burkina Faso', id: 'BF' },
            { name: 'Burundi', id: 'BI' },
            { name: 'Cambodia', id: 'KH' },
            { name: 'Cameroon', id: 'CM' },
            { name: 'Canada', id: 'CA' },
            { name: 'Cape Verde', id: 'CV' },
            { name: 'Cayman Islands', id: 'KY' },
            { name: 'Central African Republic', id: 'CF' },
            { name: 'Chad', id: 'TD' },
            { name: 'Chile', id: 'CL' },
            { name: 'China', id: 'CN' },
            { name: 'Chinese Taipei', id: 'TW' },
            { name: 'Christmas Island', id: 'CX' },
            { name: 'Cocos (Keeling) Islands', id: 'CC' },
            { name: 'Colombia', id: 'CO' },
            { name: 'Comoros', id: 'KM' },
            { name: 'Congo', id: 'CG' },
            { name: 'Congo, the Democratic Republic of the', id: 'CD' },
            { name: 'Cook Islands', id: 'CK' },
            { name: 'Costa Rica', id: 'CR' },
            { name: "Cote d'Ivoire", id: 'CI' },
            { name: 'Croatia', id: 'HR' },
            { name: 'Cuba', id: 'CU' },
            { name: 'Curaçao', id: 'CW' },
            { name: 'Cyprus', id: 'CY' },
            { name: 'Czech Republic', id: 'CZ' },
            { name: 'Denmark', id: 'DK' },
            { name: 'Djibouti', id: 'DJ' },
            { name: 'Dominica', id: 'DM' },
            { name: 'Dominican Republic', id: 'DO' },
            { name: 'Ecuador', id: 'EC' },
            { name: 'Egypt', id: 'EG' },
            { name: 'El Salvador', id: 'SV' },
            { name: 'Equatorial Guinea', id: 'GQ' },
            { name: 'Eritrea', id: 'ER' },
            { name: 'Estonia', id: 'EE' },
            { name: 'Ethiopia', id: 'ET' },
            { name: 'Falkland Islands (Malvinas)', id: 'FK' },
            { name: 'Faroe Islands', id: 'FO' },
            { name: 'Fiji', id: 'FJ' },
            { name: 'Finland', id: 'FI' },
            { name: 'France', id: 'FR' },
            { name: 'French Guiana', id: 'GF' },
            { name: 'French Polynesia', id: 'PF' },
            { name: 'French Southern Territories', id: 'TF' },
            { name: 'Gabon', id: 'GA' },
            { name: 'Gambia', id: 'GM' },
            { name: 'Georgia', id: 'GE' },
            { name: 'Germany', id: 'DE' },
            { name: 'Ghana', id: 'GH' },
            { name: 'Gibraltar', id: 'GI' },
            { name: 'Greece', id: 'GR' },
            { name: 'Greenland', id: 'GL' },
            { name: 'Grenada', id: 'GD' },
            { name: 'Guadeloupe', id: 'GP' },
            { name: 'Guatemala', id: 'GT' },
            { name: 'Guernsey', id: 'GG' },
            { name: 'Guinea', id: 'GN' },
            { name: 'Guinea-Bissau', id: 'GW' },
            { name: 'Guyana', id: 'GY' },
            { name: 'Haiti', id: 'HT' },
            { name: 'Heard Island and McDonald Islands', id: 'HM' },
            { name: 'Holy See (Vatican City State)', id: 'VA' },
            { name: 'Honduras', id: 'HN' },
            { name: 'Hong Kong', id: 'HK' },
            { name: 'Hungary', id: 'HU' },
            { name: 'Iceland', id: 'IS' },
            { name: 'India', id: 'IN' },
            { name: 'Indonesia', id: 'ID' },
            { name: 'Iran, Islamic Republic of', id: 'IR' },
            { name: 'Iraq', id: 'IQ' },
            { name: 'Ireland', id: 'IE' },
            { name: 'Isle of Man', id: 'IM' },
            { name: 'Israel', id: 'IL' },
            { name: 'Italy', id: 'IT' },
            { name: 'Jamaica', id: 'JM' },
            { name: 'Japan', id: 'JP' },
            { name: 'Jersey', id: 'JE' },
            { name: 'Jordan', id: 'JO' },
            { name: 'Kazakhstan', id: 'KZ' },
            { name: 'Kenya', id: 'KE' },
            { name: 'Kiribati', id: 'KI' },
            { name: "Korea, Democratic People's Republic of", id: 'KP' },
            { name: 'Korea, Republic of', id: 'KR' },
            { name: 'Kuwait', id: 'KW' },
            { name: 'Kyrgyzstan', id: 'KG' },
            { name: "Lao People's Democratic Republic", id: 'LA' },
            { name: 'Latvia', id: 'LV' },
            { name: 'Lebanon', id: 'LB' },
            { name: 'Lesotho', id: 'LS' },
            { name: 'Liberia', id: 'LR' },
            { name: 'Libyan Arab Jamahiriya', id: 'LY' },
            { name: 'Liechtenstein', id: 'LI' },
            { name: 'Lithuania', id: 'LT' },
            { name: 'Luxembourg', id: 'LU' },
            { name: 'Macao', id: 'MO' },
            { name: 'Macedonia, the former Yugoslav Republic of', id: 'MK' },
            { name: 'Madagascar', id: 'MG' },
            { name: 'Malawi', id: 'MW' },
            { name: 'Malaysia', id: 'MY' },
            { name: 'Maldives', id: 'MV' },
            { name: 'Mali', id: 'ML' },
            { name: 'Malta', id: 'MT' },
            { name: 'Martinique', id: 'MQ' },
            { name: 'Mauritania', id: 'MR' },
            { name: 'Mauritius', id: 'MU' },
            { name: 'Mayotte', id: 'YT' },
            { name: 'Mexico', id: 'MX' },
            { name: 'Moldova, Republic of', id: 'MD' },
            { name: 'Monaco', id: 'MC' },
            { name: 'Mongolia', id: 'MN' },
            { name: 'Montenegro', id: 'ME' },
            { name: 'Morocco', id: 'MA' },
            { name: 'Montserrat', id: 'MS' },
            { name: 'Mozambique', id: 'MZ' },
            { name: 'Myanmar', id: 'MM' },
            { name: 'Namibia', id: 'NA' },
            { name: 'Nauru', id: 'NR' },
            { name: 'Nepal', id: 'NP' },
            { name: 'Netherlands', id: 'NL' },
            { name: 'New Caledonia', id: 'NC' },
            { name: 'New Zealand', id: 'NZ' },
            { name: 'Nicaragua', id: 'NI' },
            { name: 'Niger', id: 'NE' },
            { name: 'Nigeria', id: 'NG' },
            { name: 'Niue', id: 'NU' },
            { name: 'Norfolk Island', id: 'NF' },
            { name: 'Norway', id: 'NO' },
            { name: 'Oman', id: 'OM' },
            { name: 'Pakistan', id: 'PK' },
            { name: 'Palestinian Territory, Occupied', id: 'PS' },
            { name: 'Panama', id: 'PA' },
            { name: 'Papua New Guinea', id: 'PG' },
            { name: 'Paraguay', id: 'PY' },
            { name: 'Peru', id: 'PE' },
            { name: 'Philippines', id: 'PH' },
            { name: 'Pitcairn', id: 'PN' },
            { name: 'Poland', id: 'PL' },
            { name: 'Portugal', id: 'PT' },
            { name: 'Qatar', id: 'QA' },
            { name: 'Reunion', id: 'RE' },
            { name: 'Romania', id: 'RO' },
            { name: 'Russian Federation', id: 'RU' },
            { name: 'Rwanda', id: 'RW' },
            { name: 'Saint Barthélemy', id: 'BL' },
            { name: 'Saint Helena, Ascension and Tristan da Cunha', id: 'SH' },
            { name: 'Saint Kitts and Nevis', id: 'KN' },
            { name: 'Saint Lucia', id: 'LC' },
            { name: 'Saint Martin (French part)', id: 'MF' },
            { name: 'Saint Pierre and Miquelon', id: 'PM' },
            { name: 'Saint Vincent and the Grenadines', id: 'VC' },
            { name: 'Samoa', id: 'WS' },
            { name: 'San Marino', id: 'SM' },
            { name: 'Sao Tome and Principe', id: 'ST' },
            { name: 'Saudi Arabia', id: 'SA' },
            { name: 'Senegal', id: 'SN' },
            { name: 'Serbia', id: 'RS' },
            { name: 'Seychelles', id: 'SC' },
            { name: 'Sierra Leone', id: 'SL' },
            { name: 'Singapore', id: 'SG' },
            { name: 'Sint Maarten (Dutch part)', id: 'SX' },
            { name: 'Slovakia', id: 'SK' },
            { name: 'Slovenia', id: 'SI' },
            { name: 'Solomon Islands', id: 'SB' },
            { name: 'Somalia', id: 'SO' },
            { name: 'South Africa', id: 'ZA' },
            { name: 'South Georgia and the South Sandwich Islands', id: 'GS' },
            { name: 'South Sudan', id: 'SS' },
            { name: 'Spain', id: 'ES' },
            { name: 'Sri Lanka', id: 'LK' },
            { name: 'Sudan', id: 'SD' },
            { name: 'Suriname', id: 'SR' },
            { name: 'Svalbard and Jan Mayen', id: 'SJ' },
            { name: 'Swaziland', id: 'SZ' },
            { name: 'Sweden', id: 'SE' },
            { name: 'Switzerland', id: 'CH' },
            { name: 'Syrian Arab Republic', id: 'SY' },
            { name: 'Tajikistan', id: 'TJ' },
            { name: 'Tanzania, United Republic of', id: 'TZ' },
            { name: 'Thailand', id: 'TH' },
            { name: 'Timor-Leste', id: 'TL' },
            { name: 'Togo', id: 'TG' },
            { name: 'Tokelau', id: 'TK' },
            { name: 'Tonga', id: 'TO' },
            { name: 'Trinidad and Tobago', id: 'TT' },
            { name: 'Tunisia', id: 'TN' },
            { name: 'Turkey', id: 'TR' },
            { name: 'Turkmenistan', id: 'TM' },
            { name: 'Turks and Caicos Islands', id: 'TC' },
            { name: 'Tuvalu', id: 'TV' },
            { name: 'Uganda', id: 'UG' },
            { name: 'Ukraine', id: 'UA' },
            { name: 'United Arab Emirates', id: 'AE' },
            { name: 'United Kingdom', id: 'GB' },
            { name: 'United States', id: 'US' },
            { name: 'Uruguay', id: 'UY' },
            { name: 'Uzbekistan', id: 'UZ' },
            { name: 'Vanuatu', id: 'VU' },
            { name: 'Venezuela, Bolivarian Republic of', id: 'VE' },
            { name: 'Vietnam', id: 'VN' },
            { name: 'Virgin Islands, British', id: 'VG' },
            { name: 'Wallis and Futuna', id: 'WF' },
            { name: 'Western Sahara', id: 'EH' },
            { name: 'Yemen', id: 'YE' },
            { name: 'Zambia', id: 'ZM' },
            { name: 'Zimbabwe', id: 'ZW' }
        ],
        allStates: [
            { name: 'Alabama', id: 'AL' },
            { name: 'Alaska', id: 'AK' },
            { name: 'Arizona', id: 'AZ' },
            { name: 'Arkansas', id: 'AR' },
            { name: 'California', id: 'CA' },
            { name: 'Colorado', id: 'CO' },
            { name: 'Connecticut', id: 'CT' },
            { name: 'Delaware', id: 'DE' },
            { name: 'District of Columbia', id: 'DC' },
            { name: 'Florida', id: 'FL' },
            { name: 'Georgia', id: 'GA' },
            { name: 'Guam', id: 'GU' },
            { name: 'Hawaii', id: 'HI' },
            { name: 'Idaho', id: 'ID' },
            { name: 'Illinois', id: 'IL' },
            { name: 'Indiana', id: 'IN' },
            { name: 'Iowa', id: 'IA' },
            { name: 'Kansas', id: 'KS' },
            { name: 'Kentucky', id: 'KY' },
            { name: 'Louisiana', id: 'LA' },
            { name: 'Maine', id: 'ME' },
            { name: 'Maryland', id: 'MD' },
            { name: 'Massachusetts', id: 'MA' },
            { name: 'Michigan', id: 'MI' },
            { name: 'Minnesota', id: 'MN' },
            { name: 'Mississippi', id: 'MS' },
            { name: 'Missouri', id: 'MO' },
            { name: 'Montana', id: 'MT' },
            { name: 'Nebraska', id: 'NE' },
            { name: 'Nevada', id: 'NV' },
            { name: 'New Hampshire', id: 'NH' },
            { name: 'New Jersey', id: 'NJ' },
            { name: 'New Mexico', id: 'NM' },
            { name: 'New York', id: 'NY' },
            { name: 'North Carolina', id: 'NC' },
            { name: 'North Dakota', id: 'ND' },
            { name: 'Ohio', id: 'OH' },
            { name: 'Oklahoma', id: 'OK' },
            { name: 'Oregon', id: 'OR' },
            { name: 'Pennsylvania', id: 'PA' },
            { name: 'Puerto Rico', id: 'PR' },
            { name: 'Rhode Island', id: 'RI' },
            { name: 'South Carolina', id: 'SC' },
            { name: 'South Dakota', id: 'SD' },
            { name: 'Tennessee', id: 'TN' },
            { name: 'Texas', id: 'TX' },
            { name: 'United States Minor Outlying Islands', id: 'UM' },
            { name: 'US Virgin Islands', id: 'VI' },
            { name: 'Utah', id: 'UT' },
            { name: 'Vermont', id: 'VT' },
            { name: 'Virginia', id: 'VA' },
            { name: 'Washington', id: 'WA' },
            { name: 'West Virginia', id: 'WV' },
            { name: 'Wisconsin', id: 'WI' },
            { name: 'Wyoming', id: 'WY' }
        ],
        rules: {
            firstName: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                }
            ],
            lastName: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                }
            ],
            company: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                }
            ],
            jobFunction: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            country: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            state: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            notState: [
                {
                    required: false,
                    trigger: 'change'
                }
            ],
            market: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            companySize: [
                {    
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            numberDisplays: [
                {    
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            dittoUsage: [
                {
                    required: true,
                    message: 'This field is required.',
                    trigger: 'change'
                }
            ],
            email: [
                {    
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                },
                {
                    type: 'email',
                    message: 'Please specify a valid email address.',
                    trigger: 'blur'
                },
                {
                    validator: validateEmail,
                    trigger: 'blur'
                }
            ],
            phone: [
                {    
                    required: true,
                    message: 'This field is required.',
                    trigger: 'blur'
                },
                {
                    validator: validatePhone, 
                    trigger: 'blur'
                }
            ],
            terms: [
                {    
                    validator: validateTerms,
                    trigger: 'change'
                }
            ]
        }
    }
},
computed: {
    dateYear() {
        return (new Date()).getFullYear();
    },
    stateProp() {
        if (this.newUser.country == 'US') {return 'state'}
        return 'notState'
    },
    companySizeProp() {
        if (this.newUser.market == 'Business') {return 'companySize'}
        return 'notCompanySize'
    }
},
methods: {
    validateForm(): Promise<boolean> {
        return new Promise((resolve) => {
            (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                if(!valid) {
                    let errorHTML = '';
                    for(const key in obj) {
                        for(const err of obj[key]) {
                            errorHTML = `${errorHTML}${err.message}<br>`;
                        }
                    }
                    console.error(errorHTML);
                }
                resolve(valid);
            });
        });
    },
    async selectHandler() {
        if(this.newUser.country != 'US') {
            this.newUser.state = '';
        }
    },
    async onSubmit() {
        if(!await this.validateForm()) return;

        if (this.newUser.market == 'Home') {
            this.showAirParrotMessage = true;
            return;
        }
        else {
            this.showAirParrotMessage = false;
            this.submittingForm = true;
            this.createDittoUser();
        }
    },
    async createDittoUser() {
        const newUser = {
            name: this.newUser.firstName + ' ' + this.newUser.lastName,
            email: this.newUser.email,
            phone: this.newUser.phone,
            jobDescription: this.newUser.jobFunction,
            orgName: this.newUser.company,
            numEmployees: this.newUser.companySize,
            numScreens: this.newUser.numberDisplays,
            state: this.newUser.state,
            country: this.newUser.country,
            market: this.newUser.market,
            usageTypes: this.newUser.dittoUsage == 'Both' ? 'Digital Signage;Screen Mirroring' : this.newUser.dittoUsage,
            pid: this.findQueryValue('pid')
        } as IUserRegistrationModel;

        window.sessionStorage.setItem('userData', JSON.stringify({
            orgName: newUser.orgName,
            market: newUser.market,
            numScreens: newUser.numScreens,
            numEmployees: newUser.numEmployees,
            state: newUser.state,
            country: newUser.country,
            email: newUser.email,
            jobDescription: newUser.jobDescription,
            phone: newUser.phone,
            lastName: this.newUser.lastName,
            firstName: this.newUser.firstName,
            pid: this.findQueryValue('pid')
        }));

         if (newUser) {
            const hsKey = this.findCookieValue('hubspotutk');
            newUser['hubspotutk'] = hsKey;
            this.viewLoading = true;
            
            const userCreated = await userState.registerUser(newUser);
           
            if (userCreated.success) {
                this.signUpResponseHandler(userCreated.data);
            }
            else {
                this.submittingForm = false;
                this.signUpFailureHandler(userCreated.reason);
            }
        } else {
            this.viewLoading = false;
        }
    },
    findQueryValue: function (query: string) {
        const queryArray = window.location.search.substring(1).split("&");
        
        const value = queryArray.find((arrayVal) => {
            const keyVal = arrayVal.split("=");
            return keyVal[0].toLowerCase().trim() == query.toLowerCase();
        })

        if(!value) {
            return null;
        }

        return value.split("=")[1];
    },
    findCookieValue: function (cookieKey: string) {
        const cookieArray = document.cookie.split(";");

        const cookieValue = cookieArray.find((arrayVal) => {
            const keyVal = arrayVal.split("=");
            return keyVal[0].toLowerCase().trim() == cookieKey.toLowerCase();
        })

        if (!cookieValue) {
            return;
        }

        return cookieValue.split("=")[1];
    },
    async signUpResponseHandler(user: any) {
        this.viewLoading = true;
      
        await AuthService.loginWithRedirect({
            redirect_uri: window.location.protocol +'//'+window.location.host,
            ditto_user_id: user.id,  
            ditto_org_id: user.organization.id,
            ditto_signature: user.auth0Signature,
            login_hint: user.email,
            screen_hint: 'signUp'
        });
    },
    signUpFailureHandler: function (response: any) {
        this.viewLoading = true;
    
        if (response.data && response.data.errors && response.data.errors.email && response.data.errors.email.message) {
            this.errorMessage = response.data.errors.email.message;
            this.invalidEmail = true;
            this.validateForm();
        } else {
            this.errorMessage = 'There was an error during signup. Please try again.';
        }
    
        this.viewLoading = false;
        this.invalidEmail = false;
    },
  },
  async created() {
        const signupId = this.findQueryValue('sid') as string;

        if(!signupId || signupId != 'eyJhbGciOiJIUzUxMiJ9') {
            window.location.href = 'https://www.airsquirrels.com/ditto/try';
        }
        else {
            userState = getModule(UserState);
            this.signupAllowed = true;
        }
  }
});
