import axios from 'axios';
import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import { getAuthInstance } from '../../auth';


import store from '../../store';
import { IOnboardStatus } from '../interfaces/IOnboardingStatus';
import { IOnboardingUserAction } from '../interfaces/IOnboardingUserAction';
import { IOtherUserOnboardStatus } from '../interfaces/IOtherUserOnboardingStatus';
import { IReceiver } from '../interfaces/IReceiver';
import { IRoom } from '../interfaces/IRoom';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';


export interface IOnboardStatusState {
    readonly onboardStatusLoaded: boolean;
    readonly userOnboardStatus: IOnboardStatus | undefined;
    readonly otherUsersOnboardStatus: IOtherUserOnboardStatus[];
    readonly stepInstructions: any;
    readonly currentStep: any;
    readonly getStepDetails: (step: number) => any | null;

    fetchOnboardStatus(reload?: boolean): Promise<ISqStoreActionResponse<IOnboardStatus>>;
    fetchOtherUsersOnboardStatusSuper(id: string): Promise<ISqStoreActionResponse<any>>;
    fetchOtherUsersOnboardStatus(reload?: boolean): Promise<ISqStoreActionResponse<void>>;
    attachReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<void>>;
    attachRoom(room: IRoom): Promise<ISqStoreActionResponse<void>>;
    nextStep(): Promise<ISqStoreActionResponse<void>>;
    dismiss(dontShowAgain?: boolean): Promise<ISqStoreActionResponse<void>>;
    hide(dontShowAgain?: boolean): Promise<ISqStoreActionResponse<void>>;
    start(): Promise<ISqStoreActionResponse<void>>;
    end(): Promise<ISqStoreActionResponse<void>>;
    previousStep(): Promise<ISqStoreActionResponse<void>>;
    goToStep(number: number): Promise<ISqStoreActionResponse<void>>;
    createOnboardStatus(onboarding: IOnboardStatus): Promise<ISqStoreActionResponse<IOnboardStatus>>;
    putUserActions(userActions: IOnboardingUserAction[]): Promise<ISqStoreActionResponse<void>>;
}


@Module({ dynamic: true, store, name: 'onboard' })
export default class OnboardStatusState extends VuexModule implements IOnboardStatusState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO

    private onboardStatus: IOnboardStatus = {
        step: 1 as number,
        progress: 25 as number,
        showDialog: false as boolean,
        dontShowAgain: false as boolean,
        isOnboarding: false as boolean,
    } as any;

    private otherUserOnboardStatus: IOtherUserOnboardStatus[] = [];

    private isOnboardStatusLoaded = false;
    private isOtherUserOnboardStatusLoaded = false;

    private instructions: any = [
        // ------------------------
        // ---- Overview Steps ----
        // ------------------------
        {/* 1 */
            guideStepId: 'overviewInitial',
            category: 'Overview' as string,
            name: '' as string,
            number: 1 as number,
            images: ['dittoMarketingBanner.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/',
            backStepJump: -1
        },
        {/* 2 */
            guideStepId: 'overviewGettingStarted',
            category: 'Overview' as string,
            name: 'Getting Started' as string,
            number: 2 as number,
            images: ['schoolSignage.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/',
            backStepJump: 1
        },
        {/* 3 */
            guideStepId: 'overviewReceivers',
            category: 'Overview' as string,
            name: 'Receivers' as string,
            number: 3 as number,
            images: ['welcome-to-ditto-3.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/',
            backStepJump: 2
        },
        {/* 4 */
            guideStepId: 'overviewRooms',
            category: 'Overview' as string,
            name: 'Rooms' as string,
            number: 4 as number,
            images: ['welcome-to-ditto-4.gif'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/',
            backStepJump: 3
        },


        // ------------------------
        // --- Receivers Steps  ---
        // ------------------------
        {/* 5 */
            guideStepId: 'receiversYouNeedReceiver',
            category: 'Receivers' as string,
            name: 'What you need' as string,
            number: 1 as number,
            images: ['welcome-to-ditto-5.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/',
            backStepJump: 4
        },
        {/* 6 */
            guideStepId: 'receiversYouNeedApp',
            category: 'Receivers' as string,
            name: 'What you need' as string,
            number: 2 as number,
            images: ['downloading-ditto.gif'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/',
            backStepJump: 5
        },
        {/* 7 */
            guideStepId: 'receiversClickReceivers',
            category: 'Receivers' as string,
            name: 'Creation' as string,
            number: 3 as number,
            images: ['DittoIconRGB.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/',
            backStepJump: 6
        },
        {/* 8 */
            guideStepId: 'receiversClickNewReceiver',
            category: 'Receivers' as string,
            name: 'Inventory' as string,
            number: 4 as number,
            images: [],
            dialog: false as boolean,
            banner: true as boolean,
            overlay: true as boolean,
            routerGuard: '/receivers',
            backStepJump: 7
        },
        {/* 9 */
            guideStepId: 'receiversPairingCode',
            category: 'Receivers' as string,
            name: 'Creation Wizard' as string,
            number: 5 as number,
            images: [],
            dialog: false as boolean,
            banner: true as boolean,
            overlay: true as boolean,
            routerGuard: '/receivers/new',
            backStepJump: 8
        },
        {/* 10 */
            guideStepId: 'receiversReceiverType',
            category: 'Receivers',
            name: 'Creation Wizard' as string,
            number: 6 as number,
            images: [],
            dialog: false as boolean,
            banner: true as boolean,
            overlay: true as boolean,
            routerGuard: '/receivers/new',
            entryStep: 8,
            backStepJump: 9
        },
        {/* 11 */
            guideStepId: 'receiversReceiverInformation',
            category: 'Receivers',
            name: 'Creation Wizard' as string,
            number: 7 as number,
            images: [],
            dialog: false as boolean,
            banner: true as boolean,
            overlay: true as boolean,
            routerGuard: '/receivers/new',
            entryStep: 8,
            backStepJump: 10
        },
        {/* 12 */
            guideStepId: 'receiversReceiverCreated',
            category: 'Receivers',
            name: 'Receiver successfully created!' as string,
            number: 8 as number,
            images: ['DittoIconRGB.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/receivers',
            backStepJump: -1
        },


        // ------------------------
        // ----   Rooms Steps  ----
        // ------------------------
        {/* 13 */
            guideStepId: 'roomsClickRooms',
            category: 'Rooms' as string,
            name: 'Creation' as string,
            number: 1 as number,
            images: ['DittoIconRGB.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/receivers',
            backStepJump: -1
        },
        {/* 14 */
            guideStepId: 'roomsInventory',
            category: 'Rooms',
            name: 'Inventory' as string,
            number: 10 as number,
            images: [],
            dialog: false as boolean,
            banner: true as boolean,
            overlay: true as boolean,
            routerGuard: '/rooms',
            backStepJump: 13
        },
        {/* 15 */
            guideStepId: 'roomsCreation',
            category: 'Rooms',
            name: 'Creation' as string,
            number: 11 as number,
            images: [],
            dialog: false as boolean,
            banner: true as boolean,
            overlay: true as boolean,
            routerGuard: '/rooms/new',
            backStepJump: 14
        },
        {/* 16 */
            guideStepId: 'roomCreationSuccessful',
            category: 'Rooms',
            name: 'Room successfully created!' as string,
            number: 0 as number,
            images: ['DittoIconRGB.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            routerGuard: '/rooms',
            backStepJump: -1
        },


        // ------------------------
        // ----  Finish Steps  ----
        // ------------------------
        {/* 17 */
            guideStepId: 'finishDittoConnect',
            name: 'Ditto Connect' as string,
            category: 'Finish',
            number: 1 as number,
            images: ['welcome-to-ditto-8.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            backStepJump: -1
        },
        {/* 18 */
            guideStepId: 'finishOtherFeatures',
            name: 'Other Features' as string,
            category: 'Finish',
            number: 2 as number,
            images: ['schoolSignage.png', 'emergency-alert.png'],
            dialog: true as boolean,
            banner: false as boolean,
            overlay: true as boolean,
            backStepJump: 17
        }
    ]


    //#region GETTERS
    get onboardStatusLoaded(): boolean {
        return this.isOnboardStatusLoaded;
    }

    get userOnboardStatus(): IOnboardStatus {
        return this.onboardStatus;
    }

    get otherUsersOnboardStatus(): IOtherUserOnboardStatus[] {
        return this.otherUserOnboardStatus;
    }

    get stepInstructions(): any {
        return this.instructions;
    }

    get currentStep(): any {
        if(!this.isOnboardStatusLoaded) return {};
        return this.instructions[this.onboardStatus.step - 1];
    }

    get getStepDetails(): (step: number) => any | null {
        return (step: number) => {
            return (step -1) < this.instructions.length ? this.instructions[step-1] : null;
        };
    }

    //#endregion


    //#region ACTIONS
    @Action
    async fetchOnboardStatus(reload = false): Promise<ISqStoreActionResponse<IOnboardStatus>> {
        if (this.isOnboardStatusLoaded && !reload) return { success: true };
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchOnboardingUrl = `${process.env.VUE_APP_API_HOST}/api/onboarding/current`;
            const axiosResponse = await axios.get(fetchOnboardingUrl, authHeader);

            const mutatedStatus = JSON.parse(JSON.stringify(this.onboardStatus));

            if(!axiosResponse.data) {
                mutatedStatus.isOnboarding = false;
                this.context.commit('setStatus', mutatedStatus);
                this.context.commit('setIsOnboardStatusLoaded', true);
                return { success: true };
            }
            else {
                axiosResponse.data.step = axiosResponse.data.step ? axiosResponse.data.step : 1;

                const entryStep = this.instructions[axiosResponse.data.step - 1].entryStep
                axiosResponse.data.step = entryStep ? entryStep : axiosResponse.data.step
            }

            this.context.commit('setStatus', axiosResponse.data);
            this.context.commit('setIsOnboardStatusLoaded', true);

            return { success: true };
        }
        catch (e:any) {
            console.error('Onboard Store Error:', e);
        }

        return { success: false, reason: 'Error fetching onboarding status.' };
    }

    @Action
    async fetchOtherUsersOnboardStatus(reload = false): Promise<ISqStoreActionResponse<void>> {
        if (this.isOtherUserOnboardStatusLoaded && !reload) return { success: true };
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchOnboardingUrl = `${process.env.VUE_APP_API_HOST}/api/onboarding/others`;
            const axiosResponse = await axios.get(fetchOnboardingUrl, authHeader);

            this.context.commit('setOtherUserStatus', axiosResponse.data);
            this.context.commit('setIsOtherUsersOnboardStatusLoaded', true);
            return { success: true };

        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }

        return { success: false, reason: 'Error fetching other users onboarding status.' };
    }

    @Action
    async fetchOtherUsersOnboardStatusSuper(id: string): Promise<ISqStoreActionResponse<any>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchOnboardingUrl = `${process.env.VUE_APP_API_HOST}/api/onboarding/byuser/${id}`;
            const axiosResponse = await axios.get(fetchOnboardingUrl, authHeader);

            return { success: true , data: axiosResponse.data};

        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }

        return { success: false, reason: 'Error fetching other users onboarding status.' };
    }

    @Action
    async nextStep(): Promise<ISqStoreActionResponse<void>> {
        try {
            const step = this.onboardStatus.step + 1;
            
            this.context.commit('setStep', step);

            try {
                await this.putUserActions([{
                    actionTagId: 'nextStep',
                    actionDescription: 'User triggered next step'
                }]);
            }
            catch { 
                // We don't wanna do anything here. 
                // Just wanna make sure that broken logging don't bring the process down.
            }

            return await this.postOnboardingUpdate();
        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }
        return { success: false, reason: 'Error moving to next step' };
    }


    @Action
    async attachReceiver(receiver: IReceiver): Promise<ISqStoreActionResponse<void>> {
        this.context.commit('setOnboardingReceiver', receiver);

        return this.postOnboardingUpdate();
    }

    @Action
    async attachRoom(room: IRoom): Promise<ISqStoreActionResponse<void>> {
        this.context.commit('setOnboardingRoom', room);

        return this.postOnboardingUpdate();
    }

    @Action
    async previousStep(): Promise<ISqStoreActionResponse<void>> {
        if(this.currentStep.backStepJump == -1) return { success: false, reason: 'Can not back up from this step' };
        
        try {
            let step = this.onboardStatus.step == 1 ? 1 : this.onboardStatus.step - 1;
            
            step = this.currentStep && this.currentStep.backStepJump ? this.currentStep.backStepJump : step;

            this.context.commit('setStep', step);

            // I'm sorry ts lint and ts gods... but at then end of the day this is JS and I'm gonna do what I want ¯\_(ツ)_/¯
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            globalRouter.push(this.currentStep.routerGuard);
            
            
            // router.push(this.currentStep.routerGuard);

            try {
                await this.putUserActions([{
                    actionTagId: 'previousStep',
                    actionDescription: 'User triggered previous step action'
                }]);
            }
            catch { 
                // We don't wanna do anything here. 
                // Just wanna make sure that broken logging don't bring the process down.
            }

            return await this.postOnboardingUpdate();
        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }
        return { success: false, reason: 'Error moving to previous step' };
    }

    @Action
    async dismiss(dontShowAgain = false): Promise<ISqStoreActionResponse<void>> {
        try {
            if(dontShowAgain) {

                this.context.commit('setDontShowAgain', true);
                this.context.commit('setIsOnboarding', false);
                try {
                    await this.putUserActions([{
                        actionTagId: 'dismiss_DONTSHOWAGAIN',
                        actionDescription: 'User permanently exited onboarding.'
                    }]);
                }
                catch { 
                    // We don't wanna do anything here. 
                    // Just wanna make sure that broken logging don't bring the process down.
                }
                return await this.postOnboardingUpdate();
            }

            this.context.commit('setShowDialog', false);

            try {
                await this.putUserActions([{
                    actionTagId: 'dismiss',
                    actionDescription: 'User temporarily exited onboarding.'
                }]);
            }
            catch { 
                // We don't wanna do anything here. 
                // Just wanna make sure that broken logging don't bring the process down.
            }

            return await this.postOnboardingUpdate();
        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }
        return { success: false, reason: 'Error hiding onboarding.' };
    }

    @Action
    async hide(): Promise<ISqStoreActionResponse<void>> {
        try {
            this.context.commit('setShowDialog', false);

            try {
                await this.putUserActions([{
                    actionTagId: 'hide',
                    actionDescription: 'Hiding onboarding because user does not have the necessary access.'
                }]);
            }
            catch { 
                // We don't wanna do anything here. 
                // Just wanna make sure that broken logging don't bring the process down.
            }

            return await this.postOnboardingUpdate();
        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }
        return { success: false, reason: 'Error dismissing onboarding.' };
    }

    @Action
    async start(): Promise<ISqStoreActionResponse<void>> {
        try {
            this.context.commit('setShowDialog', true);

            // I'm sorry ts lint and ts gods... but at then end of the day this is JS and I'm gonna do what I want ¯\_(ツ)_/¯
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            globalRouter.push(this.currentStep.routerGuard);

            try {
                await this.putUserActions([{
                    actionTagId: 'start',
                    actionDescription: 'User started onboarding.'
                }]);
            }
            catch { 
                // We don't wanna do anything here. 
                // Just wanna make sure that broken logging don't bring the process down.
            }

            return await this.postOnboardingUpdate();
        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }
        return { success: false, reason: 'Error starting onboarding.' };
    }

    @Action
    async end(): Promise<ISqStoreActionResponse<void>> {
        try {
            this.context.commit('setIsOnboarding', false);
            this.context.commit('setShowDialog', false);
            
            try {
                await this.putUserActions([{
                    actionTagId: 'end',
                    actionDescription: 'User ended onboarding.'
                }]);
            }
            catch { 
                // We don't wanna do anything here. 
                // Just wanna make sure that broken logging don't bring the process down.
            }

            return await this.postOnboardingUpdate();
        }
        catch (e:any) {
            console.error('Onboard Store Error:', e);
        }
        return { success: false, reason: 'Error ending onboarding.' };
    }

    @Action
    async goToStep(number: number): Promise<ISqStoreActionResponse<void>> {
        try {
            this.context.commit('setStep', number);

            // I'm sorry ts lint and ts gods... but at then end of the day this is JS and I'm gonna do what I want ¯\_(ツ)_/¯
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            globalRouter.push(this.currentStep.routerGuard);

            return this.postOnboardingUpdate();
        }
        catch(e:any) {
            console.error('Onboard Store Error:', e);
        }
        return { success: false, reason: 'Error on goto step' };
    }



    @Action
    public async putUserActions(userActions: IOnboardingUserAction[]): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const updateOnboardingUrl = `${process.env.VUE_APP_API_HOST}/api/onboarding/adduseractions/${this.onboardStatus.id}`;
            await axios.put(updateOnboardingUrl, userActions, authHeader);

            return { success: true };
        }
        catch(e:any) {
            console.error('Onboarding Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: e.response.data };
            }
        }

        return { success: false, reason: 'Error logging user onboarding action.'};
    }

    @Action
    private async postOnboardingUpdate(): Promise<ISqStoreActionResponse<void>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const updateOnboardingUrl = `${process.env.VUE_APP_API_HOST}/api/onboarding/${this.onboardStatus.id}`;
            await axios.put(updateOnboardingUrl, this.onboardStatus, authHeader);

            return { success: true };
        }
        catch(e:any) {
            console.error('Onboarding Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: e.response.data };
            }
        }

        return { success: false, reason: 'Error updating onboarding.'};
    }

    @Action
    async createOnboardStatus(onboarding: IOnboardStatus): Promise<ISqStoreActionResponse<IOnboardStatus>> { 
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const createOnboardingUrl = `${process.env.VUE_APP_API_HOST}/api/onboarding/`;
            const axiosResponse = await axios.post(createOnboardingUrl, onboarding, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Onboarding Store Error:', e);
            if(e.response && e.response.data) {
                return { success: false, reason: e.response.data };
            }
        }

        return { success: false, reason: 'Error creating onboarding.'};
    }


    //#endregion



    //#region MUTATIONS
    @Mutation
    async setStatus(status: IOnboardStatus): Promise<void> {
        this.onboardStatus = status;
    }

    @Mutation
    async setOtherUserStatus(status: IOtherUserOnboardStatus[]): Promise<void> {
        this.otherUserOnboardStatus = status;
    }

    @Mutation
    async setShowDialog(showDialog: boolean): Promise<void> {
        this.onboardStatus.showDialog = showDialog;
    }

    @Mutation
    async setIsOnboarding(isOnboarding: boolean): Promise<void> {
        this.onboardStatus.isOnboarding = isOnboarding;
    }

    @Mutation
    async setDontShowAgain(dontShowAgain: boolean): Promise<void> {
        this.onboardStatus.dontShowAgain = dontShowAgain;
    }

    @Mutation
    async setStep(step: number): Promise<void> {
        this.onboardStatus.step = step;
    }

    @Mutation
    async setIsOnboardStatusLoaded(isOnboardStatusLoaded: boolean): Promise<void> {
        this.isOnboardStatusLoaded = isOnboardStatusLoaded;
    }

    @Mutation
    async setIsOtherUsersOnboardStatusLoaded(isOnboardStatusLoaded: boolean): Promise<void> {
        this.isOtherUserOnboardStatusLoaded = isOnboardStatusLoaded;
    }

    @Mutation
    async setOnboardingReceiver(receiver: IReceiver): Promise<void> {
        this.onboardStatus.device = receiver;
    }

    @Mutation
    async setOnboardingRoom(room: IRoom): Promise<void> {
        this.onboardStatus.room = room;
    }
    //#endregion
}