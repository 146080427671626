import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authGuard, unAuthGuard } from '../auth';
import PageDirtyService from '../services/PageDirtyService';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Locations from '../views/locations/Locations.vue';
import LocationDetails from '../views/locations/LocationDetails.vue';
import Receivers from '../views/receivers/Receivers.vue';
import ReceiverDetails from '../views/receivers/ReceiverDetails.vue';
import NewReceiver from '../views/receivers/NewReceiver.vue';
import OrganizationReports from '../views/organization/Reports.vue';
import OrganizationCustomization from '../views/organization/Customization.vue';
import OrganizationCompany from '../views/organization/Company.vue';
import OrganizationDeployment from '../views/organization/Deployment.vue';
import OrganizationBilling from '../views/organization/Billing.vue';

import Rooms from '../views/rooms/Rooms.vue'
import RoomsDetails from '../views/rooms/RoomsDetails.vue'
import RoomBackground from '../views/rooms/room-signs/RoomBackground.vue'
import RoomInstructions from '../views/rooms/room-signs/RoomInstructions.vue'
import RoomLink from '../views/organization/RoomLink.vue'
import Roles from '../views/roles/Roles.vue'
import RoleDetails from '../views/roles/RoleDetails.vue'
import Logout from '../views/logout/Logout.vue';
import Error from '../views/Error.vue';
import Unauthorized from '../views/Unauthorized.vue';
import Users from '../views/users/Users.vue';
import SuperUsers from '../views/admin/SuperUsers.vue';
import SuperUserDetails from '../views/admin/SuperUserDetails.vue';
import SuperOrganizations from '../views/admin/SuperOrganizations.vue';
import SuperOrganizationDetails from '../views/admin/SuperOrganizationDetails.vue';
import UserDetails from '../views/users/UserDetails.vue';
import UserSwap from '../views/users/UserSwap.vue';
import Signup from '../views/signup/Signup.vue';
import PageNotFound from '../views/PageNotFound.vue';
import ServerCheck from '../views/ServerCheck.vue';
import Alerts from '../views/alerts/Alerts.vue';
import AlertServers from '../views/alerts/AlertServers.vue';
import Signage from '../views/signage/Signage.vue';
import SignageDetails from '../views/signage/SignageDetails.vue';
import SignageList from '../views/signage/SignageList.vue';

// Signage Layouts --- 
import WayFindingLayout from '../components/signage-layouts/WayfindingLayout.vue';
import InformationalLayout from '../components/signage-layouts/InformationalLayout.vue';
import FullMenuLayout from '../components/signage-layouts/FullMenuLayout.vue';
import MenuItemLayout from '../components/signage-layouts/MenuItemLayout.vue';
import DetailedInformationalLayout from '../components/signage-layouts/DetailedInformationalLayout.vue';
import SpotlightLayout from '../components/signage-layouts/SpotlightLayout.vue';
import COVIDOccupancyNoticeLayout from '../components/signage-layouts/COVIDOccupancyNoticeLayout.vue';
import COVIDSafetyNoticeLayout from '../components/signage-layouts/COVIDSafetyNoticeLayout.vue';
import COVIDOccupancyTwoLayout from '../components/signage-layouts/COVIDOccupancyTwoLayout.vue';
import COVIDMaskLayout from '../components/signage-layouts/COVIDMaskLayout.vue';
import MediaLayout from '../components/signage-layouts/MediaLayout.vue';
import LargeHeadlineInformationalLayout from '../components/signage-layouts/LargeHeadlineInformationalLayout.vue';
import Feature from '../components/signage-layouts/FeatureLayout.vue';
import FeatureTwoLayout from '../components/signage-layouts/FeatureTwoLayout.vue';
import DirectoryLayout from '../components/signage-layouts/DirectoryLayout.vue';
import StaticWebUrl from '../components/signage-layouts/StaticWebUrlLayout.vue';
import WeatherTodayLayout from '../components/signage-layouts/WeatherTodayLayout.vue';
import WeatherThreeDayLayout from '../components/signage-layouts/WeatherThreeDayLayout.vue';
import CanvaLayout from '../components/signage-layouts/CanvaLayout.vue'; 
import TwitterLayout from '../components/signage-layouts/TwitterLayout.vue';
import TemplateEngine from '../views/TemplateEngine.vue';


PageDirtyService.setWindowListener();


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Dashboard',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Dashboard
    },
    {
        path: '/signage',
        name: 'Signage',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Signage
    },
    {
        path: '/signage/:listid',
        name: 'SignageListDetails',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: SignageList
    },
    {
        path: '/signage/:listid/new',
        name: 'SignageItemSelection',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Signage,
        props: { isSelectingLayoutType: true }
    },
    {
        path: '/signage/:listid/:itemid',
        name: 'SignageItemDetails',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: SignageDetails,
        props: { isCreatingNewSignageItem: false }
    },
    {
        path: '/signage/:listid/new/:layouttype',
        name: 'SignageItemCreation',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: SignageDetails,
        props: { isCreatingNewSignageItem: true }
    },
    
    //#region signage item templates
    {
        path: '/signage/template/multiitemmenu/:id/:signature',
        component: FullMenuLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/singleitemmenu/:id/:signature',
        component: MenuItemLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/informational/:id/:signature',
        component: InformationalLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/detailedinformational/:id/:signature',
        component: DetailedInformationalLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/spotlight/:id/:signature',
        component: SpotlightLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/covid-occupancy/:id/:signature',
        component: COVIDOccupancyNoticeLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/covid-safety/:id/:signature',
        component: COVIDSafetyNoticeLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/media/:id/:signature',
        component: MediaLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/largeheadlineinformational/:id/:signature',
        component: LargeHeadlineInformationalLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/feature/:id/:signature',
        component: Feature,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/directory/:id/:signature',
        component: DirectoryLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/featuretwo/:id/:signature',
        component: FeatureTwoLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/covidoccupancytwo/:id/:signature',
        component: COVIDOccupancyTwoLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/covidmask/:id/:signature',
        component: COVIDMaskLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/staticweburl/:id/:signature',
        component: StaticWebUrl,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/weathertoday/:id/:signature',
        component: WeatherTodayLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/weatherthreeday/:id/:signature',
        component: WeatherThreeDayLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/canva/:id/:signature',
        component: CanvaLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/twitter/:id/:signature',
        component: TwitterLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/signage/template/wayfinding/:id/:signature',
        component: WayFindingLayout,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    {
        path: '/templateengine/:id/:signature',
        component: TemplateEngine,
        meta: { 
            noAuth: true,
        },
        props: true 
    },
    //#endregion
    {
        path: '/alerts',
        name: 'Alerts',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Alerts
    },
    {
        path: '/alerts/:id',
        name: 'AlertDetails',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Alerts
    },
    {
        path: '/alerts/new',
        name: 'AlertCreation',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Alerts,
        props: { creationMode: true }
    },
    {
        path: '/alertservers',
        name: 'AlertServers',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: AlertServers
    },
    {
        path: '/alertservers/new',
        name: 'AlertServerCreation',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: AlertServers,
        props: { creationMode: true }
    },
    {
        path: '/alertservers/:id',
        name: 'AlertServerDetails',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: AlertServers
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, null);            
        },
        component: Logout
    },
    {
        path: '/signup',
        name: 'Signup',
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, unAuthGuard);       
        },
        component: Signup
    },
    {
        path: '/locations',
        component: Locations,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/locations/new',
        component: LocationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true}
    },
    {
        path: '/locations/new/:id',
        component: LocationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true}
    },
    {
        path: '/locations/:id',
        component: LocationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/receivers',
        component: Receivers,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },

    {
        path: '/receivers/:id',
        component: ReceiverDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/receivers/new',
        component: NewReceiver,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true,
    },
    {
        path: '/activate',
        component: NewReceiver,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true,
    },
    {
        path: '/rooms',
        component: Rooms,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);
        },
        props: true
    },
    {
        path: '/rooms/:id',
        component: RoomsDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/rooms/new',
        component: RoomsDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true}
    },
    {
        path: '/rooms/background/:id',
        component: RoomBackground,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/rooms/instructions/:id',
        component: RoomInstructions,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/reports',
        component: OrganizationReports,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/company',
        component: OrganizationCompany,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/customization',
        component: OrganizationCustomization,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/deployment',
        component: OrganizationDeployment,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/billing',
        component: OrganizationBilling,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
    },
    {
        path: '/organization/deployment/quicklink',
        component: RoomLink,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/roles',
        component: Roles,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/roles/new',
        component: RoleDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: { creationMode:true }
    },
    {
        path: '/roles/:id',
        component: RoleDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/error',
        component: Error,
        props: true
    },
    {
        path: '/unauthorized',
        component: Unauthorized,
        props: true
    },
    {
        path: '/users/',
        component: Users,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/users/',
        component: SuperUsers,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/users/:id',
        component: SuperUserDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/organizations/',
        component: SuperOrganizations,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/organizations/:id',
        component: SuperOrganizationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/users/:id',
        component: UserDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/users/new',
        component: UserDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true }
    },
    {
        path: '/users/swap',
        component: UserSwap,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        }
    },
    {
        path: '/server_check',
        component: ServerCheck
    },
    {
        // path: '*',
        path: "/:catchAll(.*)",
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, null);            
        },
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


router.beforeEach((to) => {
    
    // Hubspot hsq definition
    (window as any).hsq = {};
    (window as any).hsq._hsq = (window as any)._hsq = (window as any)._hsq || [];

    let pathToPush = '';
    const fullPath = to.fullPath;

    // Check if we're at the catch all error page
    if(to.params.catchAll) {
        pathToPush = '/catchAll';
    }

    // Check if we are at a details page
    // For ex: rooms/623e2440-05b2-11ec-98c0-4562d2391f69
    else if(to.params.id) {
        const splitFullPath = fullPath.split("/");
        pathToPush = '/' + splitFullPath[1] + '/id';
        
    // At a regular URL whos path we can push
    // For ex: /rooms, /organization/customization
    } else {
        pathToPush = fullPath;
    }

    // We have a variable defined on the window in public > index.html
    (window as any)._hsq.push(['setPath', pathToPush]);
    (window as any)._hsq.push(['trackPageView']);

    return true;
})

export default router
