import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';

import { IRoom, IRoomDropDownList, IRoomListDisplay } from '../interfaces/IRoom';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { IModelSearchResponse, IRoomModelSearch } from '../interfaces/IModelSearch';

import { getModule } from 'vuex-module-decorators'
import UserState from './users';
import LocationState, { ILocationState } from './locations';

export interface IRoomState {
    readonly nonLegacyRooms: IRoomDropDownList[];

    fetchRoom(fetchRoomReq: {id: string, reload: boolean}): Promise<ISqStoreActionResponse<IRoom>>;
    fetchRoomByReceiverId(receiverId: string): Promise<ISqStoreActionResponse<IRoom>>;
    unlinkRooms(roomsIds: string[]): Promise<ISqStoreActionResponse<IRoom[]>>;
    removeReceivers(receivers: string[]): Promise<ISqStoreActionResponse<IRoom>>;
    addReceivers(addReceiversInput: IAddReceiversToRoomInput): Promise<ISqStoreActionResponse<IRoom>>;
    updateRoom(room: IRoom): Promise<ISqStoreActionResponse<IRoom>>;
    createRoom(room: IRoom): Promise<ISqStoreActionResponse<IRoom>>;
    deleteRoom(delRoomReq: {roomId: string, locationId?: string}): Promise<ISqStoreActionResponse<void>>;
    fetchRoomsDDL(reload?: boolean): Promise<ISqStoreActionResponse<IRoomDropDownList[]>>;
    fetchRoomsDDLAvailableForReceiver(fetchRoomsDDLAvailableForReceiverReq: {activeRoom: IRoom | null, isSSP: boolean, reload: boolean}): Promise<ISqStoreActionResponse<IRoomDropDownList[]>>
    fetchRoomDisplayList(roomSearch: IRoomModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IRoomListDisplay[]>>>;
    addLocation(addRemoveLocationInput: IAddRemoveLocationInput): Promise<ISqStoreActionResponse<void>>
    removeLocation(addRemoveLocationInput: IAddRemoveLocationInput): Promise<ISqStoreActionResponse<void>>
}

export interface IAddReceiversToRoomInput {
    roomId: string; 
    receiverIds: string[];
}

export interface IAddRemoveLocationInput {
    locationId: string; 
    roomIds: string[];
}


@Module({ dynamic: true, store, name: 'rooms' })
export default class RoomState extends VuexModule implements IRoomState {

    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private room: IRoom = {} as IRoom;
    private isRoomLoaded = false;
    private roomsDropDownList: IRoomDropDownList[] = [];
    private isRoomDDLLoaded = false;

    //#region GETTERS
    
    get nonLegacyRooms(): IRoomDropDownList[] {  
        const rooms = this.roomsDropDownList.filter((room: IRoomDropDownList) => {
            return !room.devices || room.devices == 'SSP'
        })

        return rooms ? rooms : [];
    }
    
    //#region ACTIONS
    
    @Action
    async fetchRoom(fetchRoomReq: {id: string, reload: boolean}): Promise<ISqStoreActionResponse<IRoom>> {
        if (!fetchRoomReq.reload && (this.isRoomLoaded && this.room.id == fetchRoomReq.id)) return { success: true, data: Object.assign({}, this.room)};

        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchRoomsUrl = `${process.env.VUE_APP_API_HOST}/api/rooms/${fetchRoomReq.id}`;

            const axiosResponse = await axios.get(fetchRoomsUrl, authHeader);
            
            this.context.commit('setRoom', axiosResponse.data);
            this.context.commit('setIsRoomLoaded', true);
            
            return { success: true, data: Object.assign({}, this.room) };
        }
        catch(e:any) {
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: 'Error fetching rooms.' };
    }

    @Action
    async fetchRoomByReceiverId(receiverId: string): Promise<ISqStoreActionResponse<IRoom>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchRoomsUrl = `${process.env.VUE_APP_API_HOST}/api/rooms/${receiverId}?byrec=true`;

            const axiosResponse = await axios.get(fetchRoomsUrl, authHeader);
                        
            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: 'Error fetching rooms.' };
    }
    
    // if this room has a location fetch it again
    @Action
    async unlinkRooms(roomIds: string[]): Promise<ISqStoreActionResponse<IRoom[]>> {
        let errorMessage = 'Error deactivating rooms.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
            const unlinkRoomsUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms/unlinkrooms`;
            const payload = { roomIds: roomIds };

            const axiosResponse = await axios.post(unlinkRoomsUrl, payload, authHeader);

            if(axiosResponse.data.error) {
                return {
                    success: false, reason: axiosResponse.data.error.message
                }
            }

            // const locations = this.rooms.filter(x => roomIds.includes(x.id)).map(x=>x.location).filter(x=>!!x);
            // const locationState: ILocationState = getModule(LocationState);
            
            // for(const loc of locations) {
            //     locationState.fetchLocation(loc.id);
            // }

            // const mutatedRooms = [
            //     ...this.rooms.filter(x=> !axiosResponse.data.rooms.map((x: IRoom)=>x.id).includes(x.id)),
            //     ...axiosResponse.data.rooms
            // ];

            // this.context.commit('setRooms', mutatedRooms);

            const userState = getModule(UserState);
            userState.fetchSubscriptionStatus(true);

            return { success: true, data:axiosResponse.data.rooms };

        }
        catch(e:any) {
            if(e.response && e.response.data) {
                errorMessage = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMessage };
    }

    @Action
    async removeReceivers(receivers: string[]): Promise<ISqStoreActionResponse<IRoom>> {
        let errorMessage = 'Error removing receivers from room.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const removeReceiversUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms/removeDevices`;
            const payload = { deviceIds: receivers };
            const axiosResponse = await axios.post(removeReceiversUrl, payload, authHeader);
            
            // const locations = this.rooms.filter(x => axiosResponse.data.map((m: IRoom)=>m.id).includes(x.id)).map(x=>x.location).filter(x=>!!x);
            // const locationState: ILocationState = getModule(LocationState);
            // for(const loc of locations) {
            //     locationState.fetchLocation(loc.id);
            // }

            // const mutatedRooms = [
            //     ...this.rooms.filter(x=> !axiosResponse.data.map((x: IRoom)=>x.id).includes(x.id)),
            //     ...axiosResponse.data
            // ];

            const userState = getModule(UserState);
            userState.fetchSubscriptionStatus(true);

            this.context.commit('setRoom', axiosResponse.data);
            this.context.commit('setIsRoomLoaded', true);
            
            return { success: true, data: Object.assign({}, this.room) };
        }
        catch(e:any) {
            if(e.response && e.response.data) {
                errorMessage = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMessage };
    }

    @Action
    async addReceivers(addReceiversInput: IAddReceiversToRoomInput): Promise<ISqStoreActionResponse<IRoom>> {
        let errorMessage = 'Error adding receivers to room.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const addReceiversUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms/addDevices/${addReceiversInput.roomId}`;
            const payload = { deviceIds: addReceiversInput.receiverIds };
            const axiosResponse = await axios.post(addReceiversUrl, payload, authHeader);
            
            if(axiosResponse.data.error) {
                return {success: false, reason: `Error adding receivers to room. ${axiosResponse.data.error.message}`};
            }

            // const room = this.roomById(addReceiversInput.roomId);

            // if(room && room.location && room.location.id) {
            //     const locationState: ILocationState = getModule(LocationState);
            //     locationState.fetchLocation(room.location.id);
            // }
            
            // const mutatedRooms = [
            //     ...this.rooms.filter(x=>x.id !== addReceiversInput.roomId),
            //     axiosResponse.data.room
            // ];

            // this.context.commit('setRooms', mutatedRooms);

            const userState = getModule(UserState);
            userState.fetchSubscriptionStatus(true);

            this.context.commit('setRoom', axiosResponse.data);
            this.context.commit('setIsRoomLoaded', true);
            
            return { success: true, data: Object.assign({}, this.room) };
        }
        catch(e:any) {
            if(e.response && e.response.data) {
                errorMessage = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMessage };
    }

    @Action
    async addLocation(addRemoveLocationInput: IAddRemoveLocationInput): Promise<ISqStoreActionResponse<void>> {
        let errorMessage = 'Error adding receivers to room.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const addReceiversUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms/setlocation`;
            const axiosResponse = await axios.post(addReceiversUrl, addRemoveLocationInput, authHeader);
            
            if(axiosResponse.data.error) {
                return {success: false, reason: `Error adding receivers to room. ${axiosResponse.data.error.message}`};
            }
            
            return { success: true };
        }
        catch(e:any) {
            if(e.response && e.response.data) {
                errorMessage = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMessage};
    }

    @Action
    async removeLocation(addRemoveLocationInput: IAddRemoveLocationInput): Promise<ISqStoreActionResponse<void>> {
        let errorMessage = 'Error removing receivers from room.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const addReceiversUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms/removelocation`;
            const axiosResponse = await axios.post(addReceiversUrl, addRemoveLocationInput, authHeader);
            
            if(axiosResponse.data.error) {
                return {success: false, reason: `Error adding receivers to room. ${axiosResponse.data.error.message}`};
            }
            
            return { success: true };
        }
        catch(e:any) {
            if(e.response && e.response.data) {
                errorMessage = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMessage};
    }

    @Action
    async fetchRoomsDDL(reload = false): Promise<ISqStoreActionResponse<IRoomDropDownList[]>> {
         try {
            if(!reload && this.isRoomDDLLoaded) return { success: true, data: this.roomsDropDownList };

            const authHeader = await getAuthInstance().getAxiosHeader();

            const availableRoomsUrl = `${process.env.VUE_APP_API_HOST}/api/rooms/dropdownlist`;
            
            const axiosResponse = await axios.get(availableRoomsUrl, authHeader);

            this.context.commit('setRoomsDDL', axiosResponse.data);
            this.context.commit('setIsRoomDDLLoaded', true);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: 'Error fetching available rooms.' };
    }

    @Action
    async fetchRoomsDDLAvailableForReceiver(fetchRoomsDDLAvailableForReceiverReq: {activeRoom: IRoom | null, isSSP: boolean, reload: boolean}): Promise<ISqStoreActionResponse<IRoomDropDownList[]>> { 
        try {
            const allRoomsDDL = (await this.fetchRoomsDDL(fetchRoomsDDLAvailableForReceiverReq.reload)).data ?? [];
            const activeRoom = fetchRoomsDDLAvailableForReceiverReq.activeRoom;
            
            let aRoomsDDL: IRoomDropDownList[] = allRoomsDDL ?? [];
            if(activeRoom && aRoomsDDL && !aRoomsDDL.some((x) => x.id === activeRoom?.id)) {
                aRoomsDDL = [...[{
                    id: activeRoom.id,
                    type: activeRoom.type,
                    roomName: activeRoom.roomName,
                    roomCode: activeRoom.roomCode as string
                }], ...allRoomsDDL ?? []];
            }

            if(fetchRoomsDDLAvailableForReceiverReq.isSSP) {// if room is SSP then filter out any room that is not a SSP room or null
                aRoomsDDL = aRoomsDDL.filter((room: IRoomDropDownList) => {
                    return !room.devices || room.devices == 'SSP'
                })
            }
            else {// its a legacy receiver and we can only have one in a room
                aRoomsDDL = aRoomsDDL.filter((room: IRoomDropDownList) => {
                    return !room.devices
                })
            }

            if(activeRoom && aRoomsDDL && !aRoomsDDL.some((x) => x.id === activeRoom?.id)) {
                aRoomsDDL.push({
                    id: activeRoom.id,
                    roomCode: activeRoom.roomCode,
                    roomName: activeRoom.roomName,
                    type: activeRoom.type,
                    enableAlerts: activeRoom.enableAlerts,
                    enableSignage: activeRoom.enableSignage
                } as IRoomDropDownList);
            }

            return { success: true, data: aRoomsDDL };
        }
        catch(e:any) {
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: 'Error fetching available rooms.' };
    }

    @Action
    async deleteRoom(delRoomReq: {roomId: string, locationId?: string}): Promise<ISqStoreActionResponse<void>> {
        let errorMessage = 'Error updating room.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const deleteRoomUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms/${delRoomReq.roomId}`;
            await axios.delete(deleteRoomUrl, authHeader);

            if(delRoomReq.locationId) {
                const locationState: ILocationState = getModule(LocationState);
                locationState.fetchLocation(delRoomReq.locationId);
            }

            // const mutatedRooms = [
            //     ...this.rooms.filter(x=> !(x.id.includes(roomId)))
            // ];

            // this.context.commit('setRooms', mutatedRooms);

            const userState = getModule(UserState);
            userState.fetchSubscriptionStatus(true);

            return { success: true };
        }
        catch(e:any) {
            if(e.response && e.response.data) {
                errorMessage = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMessage };
    }

    @Action
    async updateRoom(room: IRoom): Promise<ISqStoreActionResponse<IRoom>> {
        let errorMessage = 'Error updating room.';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateRoomUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms/${room.id}`;
            const axiosResponse = await axios.put(updateRoomUrl, room, authHeader);

            if(axiosResponse.data.error) {
                return {success: false, reason: axiosResponse.data.error.message };
            }

            //const oldRoom = this.roomById(room.id);
            const locationState = getModule(LocationState);
            
            if(room && room.location && room.location.id) {
                await locationState.fetchLocation(room.location.id);
            }

            if(axiosResponse.data.location) {
                if(room && room.location != axiosResponse.data.location) {
                    await locationState.fetchLocation(axiosResponse.data.location.id);
                }
            }

            this.context.commit('setRoom', axiosResponse.data);
            this.context.commit('setIsRoomLoaded', true);
            
            return { success: true, data: Object.assign({}, this.room) };
        }
        catch(e:any) {
            if(e.response && e.response.data) {
                errorMessage = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMessage};
    }

    @Action
    async createRoom(room: IRoom): Promise<ISqStoreActionResponse<IRoom>> {
        const roomCreationData = {
            id: undefined,
            roomName: room.roomName,
            organization: room.organization,
            roomCode: room.roomCode,
            localBinaries: {},
            groups: room.groups,
            generate_binaries: true,
            location: room.location,
            showConnectionInfo: room.showConnectionInfo,
            enableMirroring: room.enableMirroring,
            showSignageDateTime: room.showSignageDateTime,
            signageDateTimeLocation: room.signageDateTimeLocation,
            enableModeratorControls: room.enableModeratorControls,
            moderatorConnectionType: room.moderatorConnectionType,
            moderatorPassword: room.moderatorPassword,
            enableHigherResolution: room.enableHigherResolution,
            enableSignage: room.enableSignage,
            enableAlerts: room.enableAlerts,
            connectionInfoPosition: room.connectionInfoPosition,

            useLocalBinaries: false,
            macOS_local_binary: '',
            win32_local_binary: '',
            win64_local_binary: ''
        }

        roomCreationData.useLocalBinaries = room.useLocalBinaries;
        // if (test2.useLocalBinaries) {
        //     test2['macOS_local_binary'] = room.macOS_local_binary;
        //     test2['win32_local_binary'] = room.win32_local_binary;
        //     test2['win64_local_binary'] = room.win64_local_binary;
        // }

        let errorMsg = '';
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();
        
            const updateRoomUrl = `${ process.env.VUE_APP_API_HOST }/api/rooms`;
            const axiosResponse = await axios.post(updateRoomUrl, roomCreationData, authHeader);
            
            if(axiosResponse.data.location) {
                const locationState: ILocationState = getModule(LocationState);
                locationState.fetchLocation(axiosResponse.data.location.id);
            }

            this.context.commit('setRoom', axiosResponse.data);
            this.context.commit('setIsRoomLoaded', true);
            
            return { success: true, data: Object.assign({}, this.room) };
        }
        catch(e:any) {
            if(e.response.status == 409) {
                errorMsg = e.response.data;
            }
            console.error('Rooms Store Error:', e);
        }

        return { success: false, reason: errorMsg? errorMsg : 'Error creating room.'};
    }

    @Action
    async fetchRoomDisplayList(roomSearch: IRoomModelSearch): Promise<ISqStoreActionResponse<IModelSearchResponse<IRoomListDisplay[]>>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            let fetchRoomUrl = `${process.env.VUE_APP_API_HOST}/api/rooms`;

            if(roomSearch.pageIndex && roomSearch.pageSize) {
                fetchRoomUrl = `${fetchRoomUrl}?pageIndex=${roomSearch.pageIndex}&pageSize=${roomSearch.pageSize}`;

                if(roomSearch.organizationId) {
                    fetchRoomUrl = `${fetchRoomUrl}&organizationId=${roomSearch.organizationId}`;
                }

                if(roomSearch.locationId) {
                    fetchRoomUrl = `${fetchRoomUrl}&locationId=${roomSearch.locationId}`;
                }

                if(roomSearch.status) {
                    fetchRoomUrl = `${fetchRoomUrl}&status=${roomSearch.status}`;
                }

                if(roomSearch.isActive !== null && roomSearch.isActive !== undefined) {
                    fetchRoomUrl = `${fetchRoomUrl}&isActive=${roomSearch.isActive}`;
                }

                if(roomSearch.name) {
                    fetchRoomUrl = `${fetchRoomUrl}&name=${roomSearch.name}`;
                }

                if(roomSearch.roomCode) {
                    fetchRoomUrl = `${fetchRoomUrl}&roomCode=${roomSearch.roomCode}`;
                }

                if(roomSearch.sortField && roomSearch.sortOrder) {
                    fetchRoomUrl = `${fetchRoomUrl}&sortField=${roomSearch.sortField}&sortOrder=${roomSearch.sortOrder}`;
                }
            }

            const axiosResponse = await axios.get(fetchRoomUrl, authHeader);

            return { success: true, data: {itemsCount: axiosResponse.data.itemsCount, data: axiosResponse.data.data} as IModelSearchResponse<IRoomListDisplay[]> };
        }
        catch(e:any) {
            console.error('Room Store Error:', e);
        }

        return { success: false, reason: 'Error searching rooms.' };
    }
    //#endregion



    //#region MUTATIONS
    @Mutation
    async setRoom(room: IRoom): Promise<void> {
        this.room = room;
    }

    @Mutation
    async setIsRoomLoaded(isLoaded: boolean): Promise<void> {
        this.isRoomLoaded = isLoaded;
    }

    @Mutation
    async setRoomsDDL(roomsDropDownList: IRoomDropDownList[]): Promise<void> {
        this.roomsDropDownList = roomsDropDownList;
    } 

    @Mutation
    async setIsRoomDDLLoaded(isRoomDDLLoaded: boolean): Promise<void> {
        this.isRoomDDLLoaded = isRoomDDLLoaded;
    }
    //#endregion
}








