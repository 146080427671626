
import { defineComponent } from 'vue';
import LocationState, { ILocationState } from '../../store/modules/locations';
import { getModule } from 'vuex-module-decorators';
import { ILocation, ILocationIndexViewModel, ILocationListDisplay } from '../../store/interfaces/ILocation';
import MapContainer from '../../components/MapContainer.vue';
import ModelSelector from '../../components/ModelSelector.vue';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, IRoomListDisplay } from '../../store/interfaces/IRoom';
import { IModelSelectionPool } from '../../store/interfaces/IModelSelectionPool';
import 'bootstrap/dist/css/bootstrap.min.css';
import { mdiCheckBold } from '@mdi/js';
import PageDirtyService from '../../services/PageDirtyService';
import TierState, { ITierState } from '../../store/modules/tier';

let locationState = {} as ILocationState;
let roomState = {} as IRoomState;
let defaultLocation = {} as ILocation;
let tierState = {} as ITierState;

export default defineComponent({
	name: 'LocationDetailsView',
	data() {
		return {
			location: {} as ILocation,
			childLocations: [] as ILocationListDisplay[],
			allRooms: [] as IRoomListDisplay[],
			isEmergencyAlertsEnabled: false,
			subLocationTableSelection: [] as ILocation[],
			roomTableSelection: [] as IRoom[],
			showRoomSelector: false,
			locationSearchInput: '',
			loading: true,
			saving: false,
			roomSearchInput: '',
			rules: {
				name: [
					{
						required: true,
						message: 'Please enter a Location name.',
						trigger: 'blur',
					},
				],
			},
			mdiCheckBold,
		};
	},
	components: {
		MapContainer,
		ModelSelector,
	},
	props: ['creationMode', 'id'],
	computed: {
		processedChildLocations() {
			if(!this.childLocations || this.childLocations.length == 0) return [] as ILocationIndexViewModel[];

			const processedChildLocs = [] as ILocationIndexViewModel[];
			for(const cLoc of this.childLocations) {
				const roomCount = cLoc.rooms ? cLoc.rooms.length : 0;
				let receiverCount = 0;

				for(const room of cLoc.rooms) {
					receiverCount += room._devices ? room._devices.length : 0;
				}

				processedChildLocs.push({
					id: cLoc.id,
					name: cLoc.name,
					deviceCount: receiverCount,
					roomCount: roomCount
				});
			}

			return processedChildLocs;
		},
		locationOrSublocation(): (capital: boolean) => string {
			return (capital: boolean) => {
				let returnVal = this.location.parentLocation && this.location.parentLocation.id ? 'sublocation' : 'location';
				if (capital) {
					returnVal = this.location.parentLocation && this.location.parentLocation.id ? 'Sublocation' : 'Location';
				}

				return returnVal;
			};
		},
		locationRooms(): IRoomListDisplay[] | undefined {
			if (!this.allRooms || !this.location || !this.location.id) return undefined;

			const locationRooms = this.allRooms.filter((x: IRoomListDisplay)=> {
				//console.log(x);
				return x.location.id == this.location.id;
			});

			if(locationRooms.length < 1) return undefined;

			
			if (!this.roomSearchInput || this.roomSearchInput === '') return locationRooms;

			return locationRooms.filter(
				(x:IRoomListDisplay) =>
					x.roomName.toLowerCase().includes(this.roomSearchInput.toLowerCase()) ||
					(x.roomCode && x.roomCode.toLowerCase().includes(this.roomSearchInput.toLowerCase()))
			);
		}
	},
	methods: {
		handleSublocationClick(row: any, column: any) {
			if (column.property === 'id') return;
			this.$router.push({ path: `/locations/${row.id}` });
		},
		handleRoomClick(row: any, column: any) {
			if (column.property === 'id') return;
			this.$router.push({ path: `/rooms/${row.id}` });
		},
		addReceiverLink() {
			this.$router.push({ path: '/receivers' });
		},
		validateForm(): Promise<boolean> {
			return new Promise((resolve) => {
				(this as any).$refs['form'].validate((valid: boolean, obj: any) => {
					if (!valid) {
						let errorHTML = '';
						for (const key in obj) {
							for (const err of obj[key]) {
								errorHTML = `${errorHTML}${err.message}<br>`;
							}
						}
						console.error(errorHTML);
						(this as any).$message({
							dangerouslyUseHTMLString: true,
							message: errorHTML,
							type: 'error',
						});
					}

					resolve(valid);
				});
			});
		},
		async onCancel() {
			if (this.location.parentLocation) {
				(this as any).$refs['form'].clearValidate();
				this.$router.push({ path: `/locations/${this.location.parentLocation.id}` });
				return;
			}

			this.$router.push({ path: '/locations' });
		},
		async onSave() {
			if (!(await this.validateForm())) return;
			this.saving = true;
			const storeResponse = await locationState.updateLocation(this.location);

			if (!storeResponse.success) {
				(this as any).$message.error(
					storeResponse.reason ? `Location update failed: ${storeResponse.reason}` : 'Location update failed'
				);
				this.saving = false;
				return;
			}

			(this as any).$message.success('Location saved');
			this.saving = false;
			await this.loadLocation();
		},
		async onCreate() {
			if (!(await this.validateForm())) return;
			this.saving = true;
			// this is a trigger for the API to set this location as a child.
			// that makes it OK to do bad stuff. We don't want the interface to reflect that property
			(this.location as any).parentId = this.$route.params.id;

			const storeResponse = await locationState.createLocation(this.location);

			if (!storeResponse.success) {
				(this as any).$message.error(
					storeResponse.reason ? `Location creation failed: ${storeResponse.reason}` : 'Location creation failed'
				);
				this.saving = false;
				return;
			}

			(this as any).$message.success('Location created');
			this.saving = false;
			if (!storeResponse.data) return;
			PageDirtyService.markPageClean();
			this.$router.push({
				path: `/locations/${ storeResponse.data.parentLocation ? storeResponse.data.parentLocation.id : storeResponse.data.id }`
			});
		},
		async onDelete() {
			if (!this.location || !this.location.id) return; // can't delete something we don't have!

			try {
				await (this as any).$confirm(
					'Are you sure you want to delete this location?',
					'Warning',
					{
						confirmButtonText: 'Delete',
						cancelButtonText: 'Cancel',
						type: 'warning',
					}
				);
				this.saving = true;

				await locationState.deleteLocation(this.location.id);

        PageDirtyService.markPageClean();

        if (this.location.parentLocation) {
          this.$router.push({
            path: `/locations/${this.location.parentLocation.id}`,
          });
          this.saving = false;
          return;
        }
        this.saving = false;
        this.$router.push({ path: "/locations" });
        return;
      } catch {
        //canceled
      }
    },
		async onRemoveSublocations() {
			if (!this.subLocationTableSelection || this.subLocationTableSelection.length === 0)
				return;
			try {
				let confirmMessage = '';
                if(this.subLocationTableSelection.length === this.childLocations.length && this.subLocationTableSelection.length > 1) {
                    confirmMessage = `Are you sure you want to delete all ${this.subLocationTableSelection.length} sublocations?`;
                }
                else if( this.subLocationTableSelection.length === 1) {
                    confirmMessage = `Are you sure you want to delete this sublocation?`;
                }
                else {
                    confirmMessage = `Are you sure you want to delete ${this.subLocationTableSelection.length} of ${this.childLocations.length} sublocations'?`;
                }
				await (this as any).$confirm(
					confirmMessage,
					'Warning',
					{
						confirmButtonText: 'Delete',
						cancelButtonText: 'Cancel',
						type: 'warning'
					}
				);
				this.saving = true;
				const locationDeletePromise = [];

				for (const location of this.subLocationTableSelection) {
					locationDeletePromise.push(locationState.deleteLocation(location.id));
				}

				const responses = await Promise.all(locationDeletePromise);

				const failureResponses = responses.filter((x) => !x.success);

				if (failureResponses && failureResponses.length > 0) {
					for (const response of failureResponses) {
						(this as any).$message.error(
							response.reason ? `Removing sublocations failed: ${response.reason}` : 'Removing sublocations failed'
						);
					}
					
					await this.loadLocation();
					this.saving = false;
					return;
				}

				await this.loadLocation();
				(this as any).$message.success('Sublocations removed');
				this.saving = false;
			} catch {
				//canceled
			}
		},
		onEnter() {
			if (this.creationMode) {
				this.onCreate();
			} else {
				this.onSave();
			}
		},
		changeAlertPolygon(coordinates: []) {
			this.location.alertPolygon = coordinates;
		},
		async loadLocation() {
			this.location = {} as ILocation;

			if (this.creationMode) {
				this.location.parentLocation = (await locationState.fetchLocation(this.$route.params.id as string)).data ?? {} as ILocation;
				defaultLocation = Object.assign({}, this.location);
				this.loading = false;
				return;
			}

			const locationResponse = await locationState.fetchLocation(this.$route.params.id as string);
			this.location = locationResponse.data ?? {} as ILocation;
			defaultLocation = Object.assign({}, this.location);

			const subLocationResponse = await locationState.fetchLocationDisplayList({pageIndex:1, pageSize: 10000, parentId:this.location.id}) 
			this.childLocations = (subLocationResponse.data && subLocationResponse.data.data) ? subLocationResponse.data.data : [] as ILocationListDisplay[]; 

			const roomsResponse = await roomState.fetchRoomDisplayList({pageIndex: 1, pageSize: 10000});
			if(roomsResponse.success && roomsResponse.data && roomsResponse.data.data && roomsResponse.data.data.length > 0) {
				this.allRooms = roomsResponse.data.data
			}

			this.loading = false;
			return;
		},
		handleSublocationSelectionChange(val: ILocation[]) {
			this.subLocationTableSelection = val;
		},
		async activateRoomManagementSelector() {
			if(!this.allRooms || this.allRooms.length < 1) return undefined; 
			
			const availableRooms = this.allRooms.filter((x:IRoomListDisplay) => {
				
				return (!x.location || !x.location.id) || x.location.id == this.location.id
			})

			const availableRoomsMap: IModelSelectionPool[] = availableRooms.map((x) => {
					return {
						id: x.id,
						name: x.roomName,
						selected: (x.location && x.location.id == this.location.id)
					};
				}
			);

			(this.$refs as any).modelSelector.activate(availableRoomsMap);
		},
		async roomSelectionChanged(selection: IModelSelectionPool[]) {
			this.saving = true;

			// get list of rooms that are un selected and have a location... they get location removed...
			const unSelectedRoomIds = selection.filter((x) => !x.selected).map((x) => x.id);
			const removeLocationRoomIds = this.allRooms.filter((x: IRoomListDisplay) => {
				// skip if not in the un selected list
				if(!unSelectedRoomIds.includes(x.id)) return false;
				// if we it has a location we need to remove it
				if(x.location && x.location.id) return true;
				// skip everything else
				return false;
			}).map(x=>x.id);
			
			// get a list or rooms that are selected and their location was null
			const selectedRoomIds = selection.filter((x) => x.selected).map((x) => x.id);
			const addLocationRoomIds = this.allRooms.filter((x: IRoomListDisplay) => {
				// skip if not in the selected list
				if(!selectedRoomIds.includes(x.id)) return false;
				// if we don't have a location we need to add it
				if(!x.location || !x.location.id) return true;
				// skip everything else
				return false;
			}).map(x=>x.id);

			const promiseArray = [];

			if(addLocationRoomIds && addLocationRoomIds.length >0) {
				console.log('here add location', this.location.id);		
				promiseArray.push(roomState.addLocation({locationId: this.location.id, roomIds: addLocationRoomIds}))
			}

			if(removeLocationRoomIds && removeLocationRoomIds.length >0) {
				promiseArray.push(roomState.removeLocation({locationId: this.location.id, roomIds: removeLocationRoomIds}));
			}

			if(promiseArray.length == 0) {
				(this as any).$message.success('Room associations updated');
				this.saving = false;
				return;
			}
			
			const responses = await Promise.all(promiseArray);
			
			
			const failureResponses = responses.filter((x) => !x.success);

			if (failureResponses && failureResponses.length > 0) {
				for (const response of failureResponses) {
					(this as any).$message.error(
						response.reason ? `Managing rooms for location failed: ${response.reason}` : 'Managing rooms for location failed'
					);
					this.saving = false;
					return;
				}
			}

			await this.loadLocation();

			(this as any).$message.success('Room associations updated');
			this.saving = false;
		},
	},
	async created() {
		locationState = getModule(LocationState);
		roomState = getModule(RoomState);
		tierState = getModule(TierState);

		await this.loadLocation();
		await Promise.all([
			tierState.fetchTier()
		]);

		this.isEmergencyAlertsEnabled = tierState.orgTier.emergencyAlertsEnabled;

		this.loading = false;
	},
	watch: {
		async creationMode() {
			await this.loadLocation();
			(this.$refs as any).mapContainer.clear();
		},
		async id() {
			await this.loadLocation();
			(this.$refs as any).mapContainer.clear();
		},
		location: {
			handler (e) {
				PageDirtyService.monitorObjectSet([{defaultObject: defaultLocation, mutatedObject: e}]);
			},
			deep: true
		}
	},
});
