
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators';
import OnboardStatusState, { IOnboardStatusState } from '../store/modules/onboarding';
import Icon from '../components/Icon.vue';
import { mdiCheck, mdiCursorDefault, mdiAlertCircleOutline, mdiAlertCircle, mdiChevronRight, mdiChevronLeft, mdiHelpCircle } from '@mdi/js';
import { IReceiver } from '../store/interfaces/IReceiver';
import { IRoom } from '../store/interfaces/IRoom';

let onboardStatusState = {} as IOnboardStatusState;


export default defineComponent({
    name: 'Onboarding',
    data() {
        return {
            // data -----
            stepTwo: false,
            finishLaterConfirmVisible: false as boolean,
            statusFinishLaterConfirmVisible: false as boolean,

            // icons -----
            mdiCheck,
            mdiCursorDefault,
            mdiAlertCircleOutline,
            mdiAlertCircle,
            mdiChevronRight,
            mdiChevronLeft,
            mdiHelpCircle,

            // mockup onboarding object -----
            onboarding: {
                step: 1 as number,
                progress: 25 as number,
                showDialog: true as boolean,
                dontShowAgain: false as boolean,
            },

            onboardingRoom: {} as IRoom,
            onboardingReceiver: {} as IReceiver
        }
    },

    computed: {
        userOnboardStatus() {
            return onboardStatusState.userOnboardStatus;
        },

        createdRoomName(): string {
            let roomName = 'Room';

            if(this.onboardingRoom && this.onboardingRoom.roomName) {
                return (this.onboardingRoom as any).roomName;
            }

            return roomName;
        },

        createdReceiverName(): string {
            let receiverName = 'Receiver';

            if(this.onboardingReceiver && this.onboardingReceiver.displayName) {
                return (this.onboardingReceiver as any).displayName;
            }

            return receiverName;
        },

        currentMaxSteps() : number {
            switch (this.currentStep.category) {
                case 'Overview':
                    return 4;

                case 'Receivers':
                    return 3;

                case 'Rooms':
                    return 3;

                case 'Finish':
                    return 2;

                default:
                    return 0;
            }
        },

        currentStep() {
            return onboardStatusState.currentStep;
        },

        steps() {
            return onboardStatusState.stepInstructions
        },

        currentHost(): string {
            return `${window.location.protocol}//${window.location.hostname}/`;
        },
    },

    components: {
        Icon
    },

    methods: {

        nextStepClickHandler(){
            if(this.currentStep.guideStepId == 'finishOtherFeatures') {
                onboardStatusState.end();
            }
            else if(this.currentStep && this.currentStep.guideStepId != 'receiversClickReceivers' && this.currentStep.guideStepId != 'roomsClickRooms') {
                onboardStatusState.nextStep();
            }
        },

        previousStep() {
            if(this.currentStep <= 1 || this.currentStep.backStepJump == -1) return;
            onboardStatusState.previousStep();
        },

        finishOnboardLaterConfirm() {
            this.finishLaterConfirmVisible = false;
            this.statusFinishLaterConfirmVisible = false;
            this.handleOnboardClose();
        },

        handleOnboardClose() {
            onboardStatusState.dismiss(this.onboarding.dontShowAgain);
        },

        async clickSetupGuide() {
            window.open('https://help.airsquirrels.com/ditto/80-ditto-setup-guide', '_blank');

            await onboardStatusState.putUserActions([{
                actionTagId: 'userGuideLink',
                actionDescription: "User clicked Need help? Check out our setup guide"
            }]);
        },

        async clickNoDeviceHelp() {
            await onboardStatusState.putUserActions([{
                actionTagId: 'noDeviceHelpLink',
                actionDescription: "User clicked I don''t have one of these devices"
            }]);
        },

        async clickReceiverDetails() {
            await onboardStatusState.putUserActions([{
                actionTagId: 'noDeviceReceiverDetailsLink',
                actionDescription: "User clicked link to get complete details about supported receivers"
            }]);
        },

        async onDownloadDittoConnect() {
            window.open('https://www.airsquirrels.com/ditto/download', '_blank');
        },

        async onSignage() {
            await onboardStatusState.putUserActions([{
                actionTagId: 'signageButton',
                actionDescription: "User clicked digital sigange link"
            }]);
        },

        async onAlerts() {
            await onboardStatusState.putUserActions([{
                actionTagId: 'alertsButton',
                actionDescription: "User clicked emergency alerts link"
            }]);
        }
    },

    async created() {
        onboardStatusState = getModule(OnboardStatusState);

        await onboardStatusState.fetchOnboardStatus();

        this.onboardingRoom = onboardStatusState.userOnboardStatus ? onboardStatusState.userOnboardStatus.room as IRoom ?? {} as IRoom : {} as IRoom;
        this.onboardingReceiver = onboardStatusState.userOnboardStatus ? onboardStatusState.userOnboardStatus.device as IReceiver ?? {} as IReceiver : {} as IReceiver;
    },
});
