import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import AuthService from './auth/authService';
import '@stripe/stripe-js';
import VueDatePicker from '@vuepic/vue-datepicker';


// Keep import order of CSS files.
// Bootstrap > Element  ->  styles.css (Ditto skin)  ->  shared-styles.css
// ** Customizations to Element theme are done in element-variables.scss **
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/styles.css';
import './assets/css/shared-styles.scss';
import '@vuepic/vue-datepicker/dist/main.css';



AuthService.useAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENTID

}).then(()=>{
    const app = createApp(App);

    app.directive('scroll-shadow', {
        // Usage: <div v-scroll-shadow></div>
        mounted(el) {
            // On initial mount - create the div
            // element that will have the shadow styling
            const shadowElement = document.createElement("div");
            shadowElement.className = "dt-scroll-shadow";

            // Add element as the final child of 
            // the v-scroll-shadow element
            el.appendChild(shadowElement);

            // Set the initial shadow status
            const initialScrollAmount = el.clientHeight + el.scrollTop;
            if(initialScrollAmount < el.scrollHeight) {
                shadowElement.classList.add('active');
            } else {
                shadowElement.classList.remove('active');
            }
            
            // Update the shadow on scroll
            el.addEventListener('scroll', ()=> {
                window.requestAnimationFrame(()=> {
                    const amountScrolled = el.clientHeight + el.scrollTop;
                    if(amountScrolled < el.scrollHeight) {
                        shadowElement.classList.add('active');
                    } else {
                        shadowElement.classList.remove('active');
                    }
                })
            });
        },
        
        // Need to update the shadow whenever the element updates,
        // ie, when the height of element might be changing
        updated(el) {
            const shadowElement = el.querySelectorAll('.dt-scroll-shadow')[0];
            const initialScrollAmount = el.clientHeight + el.scrollTop;
            if(initialScrollAmount < el.scrollHeight) {
                shadowElement.classList.add('active');
            } else {
                shadowElement.classList.remove('active');
            }
        }
    });


    (global as any).globalRouter = router;
    app.use(ElementPlus);
    app.use(store)
    app.use(router)
    app.component('VueDatePicker', VueDatePicker);
    app.mount('#app')
});

