
import { defineComponent } from 'vue';
import LocationState, { ILocationState } from '../../store/modules/locations';
import { getModule } from 'vuex-module-decorators';
import { ILocationIndexViewModel, ILocationListDisplay } from '../../store/interfaces/ILocation';
import { mdiDelete } from '@mdi/js';
import { ILocationModelSearch } from '../../store/interfaces/IModelSearch';

let locationState = {} as ILocationState;

export default defineComponent({
    name: 'LocationsView',
    data: ()=>({
        locationSearchResults: [] as ILocationListDisplay[],
        locationCount: 10 as number,
        locationSearch: '' as string,
        selectedLocationIds: [] as string[],
        searchDebounce: null as any,
        paging: {
            pageSize: 10,
            pageIndex: 1,
            sortField: 'name',
            sortOrder: 'asc'
        } as ILocationModelSearch,
        mdiDelete,
        loading: true,
        saving: false
    }),
    inject: [
        'windowWidth'
    ],
    computed: {
        processedLocations() {
            if(!this.locationSearchResults || this.locationSearchResults.length < 1) return [];

            return this.locationSearchResults.map(x=> {
                let roomCount = x.rooms ? x.rooms.length : 0;
                let deviceCount = 0
                const locationIndexModel = {
                    id: x.id,
                    name: x.name,
                } as ILocationIndexViewModel

                for(let room of x.rooms) {
                    deviceCount += room._devices ? room._devices.length : 0;
                }

                if(x.subLocations) {
                    for(let subLoc of x.subLocations) {
                        if(!locationIndexModel.subLocationNames) locationIndexModel.subLocationNames = [];
                        locationIndexModel.subLocationNames.push(subLoc.name);

                        roomCount += subLoc.rooms.length;

                        for(let room of subLoc.rooms) {
                            deviceCount += room._devices ? room._devices.length : 0;
                        }

                    }
                }

                locationIndexModel.deviceCount = deviceCount;
                locationIndexModel.roomCount = roomCount;

                return locationIndexModel;
            })
        }
    },
    methods: {
        sortTable(e: any) {
            this.paging.sortField = !e.prop ? 'name' : e.prop.split(',')[0];
            this.paging.sortOrder = e.order == 'descending' ? 'desc' : 'asc';

            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.doLocationSearch(false);
            this.loading = true;
            }, 1000);  
        },
        handleSelectionChange(selections: any) {
            if(!selections) return;
            this.selectedLocationIds = selections.map((x: any) => x.id);
        },
        handleCurrentChange(row: any, column: any) {
            if(column.property === "id") return;
            this.$router.push({ path: `/locations/${row.id}` })
        },
        async deleteLocationClick (location: ILocationListDisplay) {
            if(this.selectedLocationIds.indexOf(location.id) === -1) {
                this.selectedLocationIds.push(location.id);
            }
            await this.massDeleteLocations();
        },
        handleFilterChange() {
            // this.loading = true;
            this.handleCurrentPageChange(1);
        },

        handleSizeChange(pageSize: number) {
            this.paging.pageSize = pageSize;
            this.handleCurrentPageChange(1);
        },

        handleCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.paging.pageIndex = pg;
                this.doLocationSearch(false);
                this.loading = true;
            }, 1000);            
        },
        async doLocationSearch(fromCreated: boolean) {
            this.loading = true;
            if(!fromCreated) {
                this.paging.name = undefined;

                if(this.locationSearch && this.locationSearch.length > 0) {
                    this.paging.name = this.locationSearch;
                }
            }

            const ssPaging = JSON.stringify(this.paging);
            sessionStorage.setItem('locationpage', ssPaging);

            const storeRes = await locationState.fetchLocationDisplayList(this.paging);

            this.locationSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data : [] as ILocationListDisplay[];
            this.locationCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;

            if(this.paging.pageIndex > 1 && this.locationSearchResults.length == 0) {
                this.paging.pageIndex = this.paging.pageIndex - 1;
                this.doLocationSearch(false);
                return;
            } else {
                this.loading = false;
                return;
            }
        },

        async massDeleteLocations() {
            if (!this.selectedLocationIds || this.selectedLocationIds.length === 0)
				return;
			try {
                let confirmMessage = '';
                if(this.selectedLocationIds.length === this.locationCount && this.selectedLocationIds.length > 1) {
                    confirmMessage = `Are you sure you want to delete all ${this.selectedLocationIds.length} locations and their sublocations?`;
                }
                else if( this.selectedLocationIds.length === 1) {
                    confirmMessage = `Are you sure you want to delete this location and its sublocations?`;
                }
                else {
                    confirmMessage = `Are you sure you want to delete ${this.selectedLocationIds.length} of ${this.locationCount} locations and their sublocations'?`;
                }
				await (this as any).$confirm(
					confirmMessage,
					'Warning',
					{
						confirmButtonText: 'Delete',
						cancelButtonText: 'Cancel',
						type: 'warning'
					}
				);
				this.saving = true;
                const locationDeletePromise = [];

				for (const location of this.selectedLocationIds) {
					locationDeletePromise.push(locationState.deleteLocation(location));
				}

                const responses = await Promise.all(locationDeletePromise);

				const failureResponses = responses.filter((x) => !x.success);

				if (failureResponses && failureResponses.length > 0) {
					for (const response of failureResponses) {
						(this as any).$message.error(
							response.reason ? `Location delete failed: ${response.reason}` : 'Location delete failed'
						);
					}
                    await this.doLocationSearch(true);
					this.saving = false;
					return;
				}

                await this.doLocationSearch(true);
                (this as any).$message.success('Locations deleted');
				this.saving = false;
            } catch {
                // Cancelled
            }
        }
    },

    async created () {
        this.loading = true;
        if(sessionStorage.getItem('locationpage')) {
            this.paging = JSON.parse(sessionStorage.getItem('locationpage') as string) as ILocationModelSearch;
            
            this.locationSearch = this.paging.name as string;
        }

        locationState = getModule(LocationState);
        this.doLocationSearch(true);
    }
});
